import React, { useEffect, useState } from 'react';
import { Typography, AppBar, Toolbar, makeStyles, Snackbar, Button, IconButton } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
	icon: {
		marginRight: theme.spacing(2),
	},
	appbar: {
		boxShadow: 'none',
	},
}));

export interface ISnackBarProps {
	opened: boolean;
	message: string;
	severity: string;
	handleClose: () => void;
}

export const SnackBarControl: React.FC<ISnackBarProps> = (props: ISnackBarProps) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setOpen(props.opened);
	}, [props.opened]);

	const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
		setOpen(false);
		props.handleClose();
		if (reason === 'clickaway') {
			return;
		}
	};

	function Alert(props) {
		return <MuiAlert elevation={6} variant="filled" {...props} />;
	}

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			open={open}
			autoHideDuration={76000}
			onClose={handleClose}
			message={props.message}
			action={
				<React.Fragment>
					<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
						<CloseIcon fontSize="small" />
					</IconButton>
				</React.Fragment>
			}
		>
			<Alert onClose={handleClose} severity={props.severity}>
				{props.message}
			</Alert>
		</Snackbar>
	);
};

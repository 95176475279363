export class NavigationLinks {
	public static readonly PUBLIC: string = '/';
	public static readonly APP: string = '/app';
	public static readonly APP_DASHBOARD: string = '/app/dashboard';
	public static readonly BUSINESS_AUTOMATION_INVOICES: string = '/app/invoices';
	public static readonly BUSINESS_AUTOMATION_INVOICES_API: string = '/app/invoices/api';
	public static readonly BUSINESS_AUTOMATION_INVOICES_EDIT: string = '/app/invoices/edit/:uid';
	public static readonly TEXT_PROCESSING_API: string = '/app/text-processing/api';
	public static readonly TEXT_PROCESSING_OCR: string = '/app/ocr';
	public static readonly TEXT_PROCESSING_TABLES: string = '/app/tables';
	public static readonly TEXT_PROCESSING_TABLES_VIEW_DOCUMENT: string = '/app/tables/view-doc/:uid';
	public static readonly LIFE_SCIENCE_API: string = '/app/lifescience/api';
	public static readonly LIFE_SCIENCE_MOLECULE_EXTRACTION: string = '/app/lifescience/molecule-extraction';
	public static readonly LIFE_SCIENCE_MOLECULE_EXTRACTION_VIEW_IMAGE: string = '/app/viewImage/:uid';
	public static readonly LIFE_SCIENCE_MOLECULE_EXTRACTION_VIEW_PDF: string = '/app/viewPdf/:uid';
	public static readonly LIFE_SCIENCE_MOLECULE_EXTRACTION_VIEW_PDF_ITEM: string = '/app/viewPdfImage/:parentuid/:uid';
	public static readonly MY_PROFILE: string = '/app/my-profile';
	public static readonly ORGANIZATION: string = '/app/organization';
	public static readonly PRICING: string = '/app/pricing';
	public static readonly FEEDBACK: string = '---This is not a REAL link ---';
	public static readonly SUBSCRIPTIONS: string = '/app/subscriptions';
	public static readonly SUBSCRIPTIONS_STEPPER: string = '/app/subscriptions/subscribe';
	public static readonly SUBSCRIPTIONS_PAYMENT: string = '/app/subscriptions/pay';
	public static readonly API_KEY: string = '/app/apikeys';
	public static readonly ADMIN_USERS: string = '/app/admin/users';
	public static readonly ADMIN_LOGS: string = '/app/admin/logs';
	public static readonly SIGN_IN: string = '/signin';
	public static readonly REGISTER: string = '/register';
	public static readonly PRIVACY_POLICY: string = '/privacy-policy';
	public static readonly COOKIE_POLICY: string = '/cookie-policy';
	public static readonly PAGE_TEXT_EXTRACTION: string = '/text-extraction';
	public static readonly PAGE_LIFE_SCIENCES: string = '/life-sciences';
	public static readonly PAGE_PUBLIC_PRICING: string = '/public-pricing';
	public static readonly PAGE_PUBLIC_CONTACT_US: string = '/contact-us';
	public static readonly PAGE_BUSINESS_AUTOMATION: string = '/business-automation';
	public static readonly DISCLAIMER: string = '/disclaimer';
	public static readonly TERMS_OF_SERVICE: string = '/tos';
	public static readonly PASSWORD_RESET: string = '/password-reset';
	public static readonly PARAM_ID: string = '/:id';
	public static readonly REGISTER_SUCCESS: string = '/registersuccess';
	public static readonly GOOGLE_REDIRECT: string = '/google/redirect';
}

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { MessagePlaceHolder } from './MessagePlaceHolder';
import { UserRoles } from 'models/UserRoles';
import GroupIcon from '@material-ui/icons/Group';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAstronaut, faTags, faLandmark } from '@fortawesome/free-solid-svg-icons';
import { Services, Button, Box, Grid, AppBar, IconButton, clsx, Toolbar, Typography, Menu, MenuItem, RouterLink, NavigationLinks, useGlobalState, React, useEffect } from 'components/globals/GlobalIncludes';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = (theme: Theme) =>
	createStyles({
		secondaryBar: {
			zIndex: 0,
		},
		menuButton: {
			marginLeft: -theme.spacing(1),
		},
		iconButtonAvatar: {
			padding: 4,
		},
		link: {
			textDecoration: 'none',
			color: lightColor,
			'&:hover': {
				color: theme.palette.common.white,
			},
		},
		button: {
			borderColor: lightColor,
		},
		icon: {
			marginRight: theme.spacing(1),
		},
		header: {
			backgroundColor: '#fff',
			color: '#666',
			// borderBottom: '1px solid #efefef',
		},
		fingerprint: {
			color: theme.palette.primary.main,
		},
	});

interface HeaderProps extends WithStyles<typeof styles> {
	onDrawerToggle: () => void;
}

function Header(props: HeaderProps) {
	const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
	const services: Services = new Services();
	const { classes, onDrawerToggle } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	function onLogout() {
		// services.cookieService.removeCookie('access_token');
		services.sessionStorage.set('access_token', null);
		window.location.href = NavigationLinks.PUBLIC;
		//setLoggedIn(false);
		//setLoggedInUser(null);
		// history.push("/");
	}

	return (
		<React.Fragment>
			<AppBar position="sticky" elevation={0} className={classes.header}>
				<Toolbar>
					<Grid container spacing={1} alignItems="center">
						{/* <Hidden smUp>
							<Grid item>
								<IconButton color="inherit" aria-label="open drawer" onClick={onDrawerToggle} className={classes.menuButton}>
									<MenuIcon />
								</IconButton>
							</Grid>
						</Hidden> */}
						<Grid item xs />
						{/* <Grid item>
							<Link className={classes.link} href="#" variant="body2">
								Go to docs
							</Link>
						</Grid>
						<Grid item>
							<Tooltip title="Alerts • No alerts">
								<IconButton color="inherit">
									<NotificationsIcon />
								</IconButton>
							</Tooltip>
						</Grid> */}

						<Grid item>
							<Typography color="inherit" variant="body2" onClick={handleClick} style={{ cursor: 'pointer' }}>
								Nice to see you, {loggedInUser && loggedInUser.name}
							</Typography>
						</Grid>
						<Grid item>
							<IconButton color="primary" className={classes.iconButtonAvatar} onClick={handleClick}>
								<FingerprintIcon className={classes.fingerprint} />
							</IconButton>
							<Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
								<MenuItem onClick={handleClose} component={RouterLink} to={NavigationLinks.MY_PROFILE}>
									<Typography color="primary" noWrap>
										{loggedInUser && loggedInUser.name}
									</Typography>
								</MenuItem>
								<MenuItem onClick={handleClose} component={RouterLink} to={NavigationLinks.MY_PROFILE}>
									{/* <AccountCircleIcon fontSize="small" className={classes.icon} /> */}
									<FontAwesomeIcon icon={faUserAstronaut} className={classes.icon} />
									Profile
								</MenuItem>
								{/* <MenuItem onClick={handleClose} component={RouterLink} to={NavigationLinks.ORGANIZATION}>
									<FontAwesomeIcon icon={faBuilding} className={classes.icon} />
									Organization
								</MenuItem> */}
								{/* <MenuItem onClick={handleClose} component={RouterLink} to={NavigationLinks.API_KEY.replace(':tab', '2')}> */}
								{/* <MenuItem onClick={handleClose} component={RouterLink} to={NavigationLinks.API_KEY}>
									<FontAwesomeIcon icon={faCode} className={classes.icon} />
									API Keys
								</MenuItem> */}
								<MenuItem onClick={handleClose} component={RouterLink} to={NavigationLinks.SUBSCRIPTIONS}>
									{/* <SubscriptionsIcon fontSize="small" className={classes.icon} /> */}
									<FontAwesomeIcon icon={faLandmark} className={classes.icon} />
									Subscriptions
								</MenuItem>
								<MenuItem onClick={handleClose} component={RouterLink} to={NavigationLinks.PRICING}>
									{/* <SubscriptionsIcon fontSize="small" className={classes.icon} /> */}
									<FontAwesomeIcon icon={faTags} className={classes.icon} />
									Pricing
								</MenuItem>
								<MenuItem onClick={() => onLogout()}>
									<PowerSettingsNewIcon fontSize="small" className={classes.icon} />
									Logout
								</MenuItem>
								{loggedInUser && loggedInUser.role === UserRoles.SUPER_ADMIN && <MenuItem disabled={true}>Admin area</MenuItem>}
								{loggedInUser && loggedInUser.role === UserRoles.SUPER_ADMIN && (
									<MenuItem onClick={handleClose} component={RouterLink} to={NavigationLinks.ADMIN_USERS}>
										<GroupIcon fontSize="small" className={classes.icon} />
										Users
									</MenuItem>
								)}
								{loggedInUser && loggedInUser.role === UserRoles.SUPER_ADMIN && (
									<MenuItem onClick={handleClose} component={RouterLink} to={NavigationLinks.ADMIN_LOGS}>
										<ReceiptIcon fontSize="small" className={classes.icon} />
										Logs
									</MenuItem>
								)}
							</Menu>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			{/* <AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
				<Toolbar>
					<Grid container alignItems="center" spacing={1}>
						<Grid item xs>
							<Typography color="inherit" variant="h5" component="h1">
								Welcome, {user.name}
							</Typography>
						</Grid>
						<Grid item>
							<Button className={classes.button} variant="outlined" color="inherit" size="small">
								Web setup
							</Button>
						</Grid>
						<Grid item>
							<Tooltip title="Help">
								<IconButton color="inherit">
									<HelpIcon />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar> */}
			{/* <AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
				<Tabs value={0} textColor="inherit">
					<Tab textColor="inherit" label="Users" />
					<Tab textColor="inherit" label="Sign-in method" />
					<Tab textColor="inherit" label="Templates" />
					<Tab textColor="inherit" label="Usage" />
				</Tabs>
			</AppBar> */}
			<MessagePlaceHolder />
		</React.Fragment>
	);
}

export default withStyles(styles)(Header);

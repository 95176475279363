import { BaseApiService } from './BaseApiService';
import ApiResponse from 'models/ApiResponse';
import { AxiosResponse } from 'axios';
import { IUserModel } from 'models/IUserModel';
import { UserStatus } from 'models/UserStatus';

export class UserService extends BaseApiService {
	public async login(email: string, password: string): Promise<any> {
		try {
			const url = 'v1.0/api/auth/login?username=' + email + '&password=' + password;
			const apiResponse: any = await this.axiosInstance.post(url); //, data, config);
			return apiResponse.data;
		} catch (error) {
			this.helperService.handleError(error, false);
		}
	}

	public async loginWithGoogle(token: string): Promise<any> {
		try {
			const url = 'v1.0/api/auth/login-google';
			const data = {
				token,
			};
			const apiResponse: any = await this.axiosInstance.post(url, data); //, data, config);
			return apiResponse.data;
		} catch (error) {
			this.helperService.handleError(error, false);
		}
	}

	public async register(name: string, email: string, password: string): Promise<any> {
		try {
			// const url = "v1.0/api/auth/register?name=" + name + "&username=" + email + "&password=" + password;
			const url = 'v1.0/api/auth/register';
			const data = {
				name,
				email,
				password,
			};
			const apiResponse: any = await this.axiosInstance.post(url, data); //, config);
			return apiResponse.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 * Send the reset password link to the user
	 *
	 * @param {string} email
	 * @return {*}  {Promise<any>}
	 * @memberof UserService
	 */
	public async sendResetPasswordLink(email: string): Promise<any> {
		try {
			// const url = "v1.0/api/auth/register?name=" + name + "&username=" + email + "&password=" + password;
			const url = 'v1.0/api/auth/password-reset-link';
			const data = {
				email,
			};
			const apiResponse: any = await this.axiosInstance.post(url, data); //, config);
			return apiResponse.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async resetPassword(resetCode: string, newPassword: string): Promise<any> {
		try {
			// const url = "v1.0/api/auth/register?name=" + name + "&username=" + email + "&password=" + password;
			const url = 'v1.0/api/auth/password-reset';
			const data = {
				resetCode,
				newPassword,
			};
			const apiResponse: any = await this.axiosInstance.post(url, data); //, config);
			return apiResponse.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async getMeWithStoredToken(): Promise<IUserModel> {
		try {
			return await this.getMe(this.accessToken);
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async getMe(accessToken: string): Promise<IUserModel> {
		try {
			const url = 'v1.0/api/users/me';
			const configOptions = this.getAuthHeader(accessToken);
			const results: AxiosResponse<IUserModel> = await this.axiosInstance.get(url, configOptions);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async getUsers(): Promise<IUserModel[]> {
		try {
			const url = 'v1.0/api/users/list';
			const configOptions = this.getAuthHeader(this.accessToken);
			const results: AxiosResponse<IUserModel[]> = await this.axiosInstance.get(url, configOptions);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 * Deletes a file from the cloud storage and from the DB. Permanently.
	 *
	 * @param {string} id
	 * @return {*}  {Promise<void>}
	 * @memberof OcrService
	 */
	public async updateStatus(id: string, status: UserStatus): Promise<void> {
		try {
			const url = 'v1.0/api/users/update/status/' + id;
			const data = {
				status,
			};
			await this.axiosInstance.post(url, data, this.apiConfig);
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 * Updates one user
	 *
	 * @param {string} id
	 * @param {IUserModel} user
	 * @return {*}  {Promise<void>}
	 * @memberof UserService
	 */
	public async update(id: string, user: IUserModel): Promise<void> {
		try {
			const url = 'v1.0/api/users/update/' + id;
			await this.axiosInstance.post(url, user, this.apiConfig);
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async updatePassword(id: string, oldPassword: string, newPassword: string): Promise<void> {
		try {
			const url = 'v1.0/api/users/update/' + id + '/password';
			const data = {
				oldPassword,
				newPassword,
			};
			await this.axiosInstance.post(url, data, this.apiConfig);
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 * Deletes a file from the cloud storage and from the DB. Permanently.
	 *
	 * @param {string} id
	 * @return {*}  {Promise<void>}
	 * @memberof OcrService
	 */
	public async delete(id: string): Promise<void> {
		try {
			const url = 'v1.0/api/users/delete/' + id;
			await this.axiosInstance.delete(url, this.apiConfig);
		} catch (error) {
			this.helperService.handleError(error);
		}
	}
}

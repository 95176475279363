import { AxiosResponse } from 'axios';
import { BaseApiService } from './BaseApiService';
import { ILog } from '../models/ILogModel';

export class LogsService extends BaseApiService {
	public async getLogsForUser(): Promise<ILog[]> {
		try {
			const url = 'v1.0/api/logs/get';
			const results: AxiosResponse<ILog[]> = await this.axiosInstance.get<ILog[]>(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async getLogsForAll(): Promise<ILog[]> {
		try {
			const url = 'v1.0/api/logs/getall';
			const results: AxiosResponse<ILog[]> = await this.axiosInstance.get<ILog[]>(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async clearLogs(): Promise<boolean> {
		try {
			const url = 'v1.0/api/logs/delete';
			const results: AxiosResponse<boolean> = await this.axiosInstance.delete<boolean>(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}
}

import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import { DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

const useStyles = makeStyles({
	avatar: {
		backgroundColor: blue[100],
		color: blue[600],
	},
});

export interface ICustomDialogProps {
	open: boolean;
	title: string;
	onClose: (value: boolean) => void;
	content: ReactElement;
	dialogActions: ReactElement;
}

export const CustomDialog: React.FC<ICustomDialogProps> = (props: ICustomDialogProps) => {
	const classes = useStyles();
	const { onClose, open, title, content, dialogActions } = props;

	return (
		<Dialog onClose={() => onClose(false)} aria-labelledby="simple-dialog-title" open={open}>
			<DialogTitle id="simple-dialog-title">{title}</DialogTitle>
			<DialogContent>{content}</DialogContent>
			<DialogActions>{dialogActions}</DialogActions>
		</Dialog>
	);
};

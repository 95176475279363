export interface IErrorModel extends Error {
	message: string;
	status: string;
	getMessageWithStatus: () => string;
	// getMessageWithStatus(): string;
}

export class ErrorModel extends Error implements IErrorModel {
	message: string;
	name: string;
	stack?: string;
	status: string;

	/**
	 * Constructor
	 */
	constructor(message: string, status?: string, stack?: string, name?: string) {
		super(message);
		this.message = message;
		this.status = status;
		this.stack = stack ? stack : null;
		this.name = name ? name : null;
	}

	/**
	 * Get a full error message starting with the status code of the response
	 * @returns string
	 */
	getMessageWithStatus(): string {
		return (this.status ? this.status : '') + ' - ' + this.message;
	}
}

import { React, Grid, Container, useEffect, Services, Box, useState, useGlobalState, makeStyles, Typography, Switch } from '../globals/GlobalIncludes';
import { ContactUs } from './ContactUs';
import { Footer } from './Footer';

const useStyles = makeStyles({});

export function PublicPageContactUs() {
	const classes = useStyles();
	const services: Services = new Services();

	return (
		<React.Fragment>
			<ContactUs />
			<Footer />
		</React.Fragment>
	);
}

export enum SubscriptionLimitMethod {
	DAYS_LIMITED = 'DAYS_LIMITED',
	CALLS_LIMITED = 'CALLS_LIMITED',
}

export enum SubscriptionProductTypes {
	BUSINESS_AUTOMATION = 'BUSINESS_AUTOMATION',
	TEXT_PROCESSING = 'TEXT_PROCESSING',
	LIFE_SCIENCE = 'LIFE_SCIENCE',
}

export enum SubscriptionProductPeriods {
	MONTH = 'MONTH',
	YEAR = 'YEAR',
}

export enum SubscriptionProductTypeTitle {
	BUSINESS_AUTOMATION = 'Business Automation',
	TEXT_PROCESSING = 'Text Processing',
	LIFE_SCIENCE = 'Life Science',
}

export enum SubscriptionProductTypeVolume {
	TEN = '10',
	ONEHUNDRED = '100',
	TWOHUNDRED = '200',
	FIVEHUNDRED = '500',
	ONETHOUSAND = '1000',
	TWOTHOUSAND = '2000',
	FIVETHOUSAND = '5000',
	TENTHOUSAND = '10000',
	TWNTYTHOUSAND = '20000',
	FIFTYTHOUSAND = '50000',
	ONEMILLION = '1000000',
}

export interface ISubscriptionType {
	name: string;
	product: SubscriptionProductTypes;
	limitMethod: SubscriptionLimitMethod;
	limitAmount: number;
	rateLimit: number; // Calls/second
	createDate: Date;
	lastModificationDate: Date;
	modifiers: IModifier[];
	stripeProductId: string;
}

export interface IModifier {
	userId: string;
	modificationDate: Date;
}

import { BaseApiService } from './BaseApiService';
import { IOcrFileModel } from '../models/ocr/IOcrFileModel';
import { AxiosResponse } from 'axios';

export class OcrService extends BaseApiService {
	/**
	 * Getting all the files which the user uploaded for OCR processing regardless their processing statuses
	 *
	 * @return {*}  {Promise<IOcrFileModel[]>}
	 * @memberof OcrService
	 */
	public async getFiles(): Promise<IOcrFileModel[]> {
		try {
			const url = 'v1.0/api/text-processing/ocr/files';
			const results: AxiosResponse<IOcrFileModel[]> = await this.axiosInstance.get<IOcrFileModel[]>(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 * Start the OCR conversion for the image based file
	 *
	 * @param {*} file
	 * @return {*}  {Promise<string>}
	 * @memberof OcrService
	 */
	public async startExtraction(file: any, isPDFConversion: boolean, isHOCR: boolean, isText: boolean): Promise<string> {
		try {
			const fd = new FormData();
			fd.append('file', file[0]);
			fd.append('isPDFConversion', isPDFConversion ? '1' : '0');
			fd.append('isHOCR', isHOCR ? '1' : '0');
			fd.append('isText', isText ? '1' : '0');

			const url = 'v1.0/api/text-processing/ocr/pdf/extract/start';
			const results: any = await this.axiosInstance.post(url, fd, this.apiConfig);
			return results.data.jobId;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 * Download a result file from the CR processing
	 *
	 * @param {IOcrFileModel} model
	 * @param {string} type
	 * @return {*}  {Promise<any>}
	 * @memberof OcrService
	 */
	// public async downloadFile(model: IOcrFileModel, type: string): Promise<any> {
	// 	try {
	// 		const url = 'v1.0/api/ocr/files/download/' + type + '/' + model.fileName;
	// 		const configOptions = this.getAuthHeader(this.accessToken);
	// 		// const fileDownload = require('js-file-download');
	// 		// this.axiosInstance.get(url, configOptions).then...
	// 		fetch(this.myBaseURL + url, configOptions).then((response) => {
	// 			if (response.status === 500) {
	// 				this.helperService.handleError(new Error(response.statusText));
	// 			} else {
	// 				// fileDownload(response.data, 'ocr.pdf');
	// 				response.blob().then((blob) => {
	// 					const url2 = window.URL.createObjectURL(blob);
	// 					const link = document.createElement('a');
	// 					link.href = url2;

	// 					link.setAttribute('download', type + '_results_for_' + model.fileName.split('.').shift() + '.' + type);
	// 					document.body.appendChild(link);
	// 					link.click();
	// 					link.remove();
	// 				});
	// 			}
	// 		}).catch(error =>{
	//             	this.helperService.handleError(error);
	//         });
	// 		// return results.data;
	// 	} catch (error) {
	// 		this.helperService.handleError(error);
	// 	}
	// }

	public async downloadFile(model: IOcrFileModel, type: string): Promise<any> {
		const url = 'v1.0/api/text-processing/ocr/files/download/' + type + '/' + model.fileName;
		const configOptions = this.getAuthHeader(this.accessToken);
		// const fileDownload = require('js-file-download');
		// this.axiosInstance.get(url, configOptions).then...

		function downloadBlobinBrowser(blob) {
			const url2 = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url2;
			link.setAttribute('download', type + '_results_for_' + model.fileName.split('.').shift() + '.' + type);
			document.body.appendChild(link);
			link.click();
			link.remove();
		}

		return fetch(this.myBaseURL + url, configOptions)
			.then(this.validateResponse)
			.then(this.readResponseAsBlob)
			.then(downloadBlobinBrowser);
	}

	public validateResponse(response) {
		if (!response.ok) {
			throw Error(response.statusText);
		}
		return response;
	}

	public readResponseAsBlob(response) {
		return response.blob();
	}

	/**
	 * Deletes a file from the cloud storage and from the DB. Permanently.
	 *
	 * @param {string} id
	 * @return {*}  {Promise<void>}
	 * @memberof OcrService
	 */
	public async delete(id: string): Promise<void> {
		try {
			const url = 'v1.0/api/text-processing/ocr/files/delete/' + id;
			await this.axiosInstance.delete(url, this.apiConfig);
		} catch (error) {
			this.helperService.handleError(error);
		}
	}
}

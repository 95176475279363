import { theme } from 'theme/theme';
import { React, Grid, Container, useEffect, Services, Box, useState, useGlobalState, makeStyles, Typography, Switch } from '../globals/GlobalIncludes';
import { DiscreteSlider } from './Components/DiscreteSlider';
import { businessAutomationPrices, textProcessingPrices } from './models/PriceTags';
import { Footer } from './Footer';
import { commonStyleSheet } from '../../theme/common.styles';
import clsx from 'clsx';

const useStyles = makeStyles({
	planContainer: {
		//border: '1px solid #ccc',
		backgroundColor: '#f3f3f3',
		borderRadius: '24px',
		'&:hover': {
			backgroundColor: '#fff',
		},
	},
	planTitle: {
		height: '80px',
		borderBottom: '1px solid #ccc',
		marginTop: '36px',
	},
	planBenefitItem: {
		padding: '16px 16px !important',
	},
	iconTop: {
		backgroundColor: '#efefef',
		padding: '30px',
		borderRadius: '50%',
		position: 'absolute',
		marginTop: '-100px',
	},
});

export function PublicPricing() {
	const classes = useStyles();
	const commonStyles = commonStyleSheet();
	const services: Services = new Services();
	const [sliderValue, setSliderValue] = useState(0);
	const [checkedAnnual, setCheckedAnnual] = React.useState(false);
	const [baPrice, setBaPrice] = useState(20);
	const [tpPrice, setTpPrice] = useState(20);
	const [lsPrice, setLsPrice] = useState(20);

	useEffect(() => {
		if (sliderValue) {
			updatePrices(!checkedAnnual);
		}
	}, [sliderValue]);

	const onSliderValueChanged = (newValue: number) => {
		setSliderValue(newValue);
	};

	const toggleAnnualChecked = () => {
		setCheckedAnnual((prev) => {
			updatePrices(prev);
			return !prev;
		});
	};

	const updatePrices = (isMonthly: boolean) => {
		const baPriceObj = getPriceObj(businessAutomationPrices, isMonthly, sliderValue);
		setBaPrice(baPriceObj.price);

		const tpPriceObj = getPriceObj(textProcessingPrices, isMonthly, sliderValue);
		setTpPrice(tpPriceObj.price);

		setLsPrice(isMonthly ? 15 : 150);
	};

	const getPriceObj = (prices: Array<any>, isMonthly: boolean, value: number) => {
		const period = isMonthly ? 'month' : 'year';
		const baPriceObj = prices.find((price) => {
			return price.amount === value && price.period === period;
		});
		return baPriceObj;
	};

	return (
		<React.Fragment>
			<section className="mbr-section content4 cid-s6OOFFfFdr" id="public_pricing" style={{ marginTop: '80px' }}>
				<div className="container">
					<div className="media-container-row">
						<div className="title col-12 col-md-8">
							<h2 className="align-center pb-3 mbr-fonts-style display-1">
								<strong>PRICING</strong>
							</h2>
						</div>
					</div>
				</div>
			</section>
			<section className="mbr-section content4 cid-s6OOFFfFdr" id="content4-1g">
				<Box display="flex" justifyContent="center" flexGrow={1}>
					<Box style={{ width: '60%' }}>
						<DiscreteSlider defaultValue={15} onValueChanged={onSliderValueChanged} />
					</Box>
				</Box>
				<Box display="flex" p={3} pt={6} justifyContent="center">
					<Box display="flex" justifyContent="flex-end" alignItems="center">
						<Typography variant="overline" display="block">
							Monthly
						</Typography>
					</Box>
					<Box display="flex" width={80} justifyContent="center" alignItems="center">
						<Switch color="primary" size="medium" checked={checkedAnnual} onChange={toggleAnnualChecked} />
					</Box>
					<Box display="flex" justifyContent="flex-start" alignItems="center">
						<Typography variant="overline" display="block">
							Annual
						</Typography>
					</Box>
				</Box>
			</section>
			<section className="pricing-table2 cid-s6gir4s6Kn" id="pricing-tables2-5">
				<div className="container">
					<div className="media-container-row">
						{/* BUSINESS AUTOMATION */}
						<div className={'plan col-12 mx-2 my-2 justify-content-center col-lg-6 ' + classes.planContainer}>
							<div className="plan-header text-center pt-5">
								<Box className={classes.iconTop}>
									<i className={clsx('icofont-search-document', commonStyles.iconTitle48)}></i>
								</Box>
								<h3 className={'plan-title mbr-fonts-style display-5 ' + classes.planTitle}>Business Automation {sliderValue}</h3>
								<div className="plan-price" style={{ marginTop: theme.spacing(4) }}>
									<span className="price-value mbr-fonts-style display-5">$</span>
									<span className="price-figure mbr-fonts-style display-1">{baPrice}</span>
								</div>
							</div>
							<div className="plan-body">
								<div className="plan-list align-center">
									<ul className="list-group list-group-flush mbr-fonts-style display-7">
										<li className={'list-group-item ' + classes.planBenefitItem}>
											{!checkedAnnual ? '/ month' : '/ year'}
											<br />
											<span style={{ backgroundColor: 'transparent', fontSize: '1rem' }}>
												<br />
												Extract invoice data from PDFs and images
											</span>
										</li>
										<li className={'list-group-item ' + classes.planBenefitItem}>Extract general key-value data pairs from PDFs and images</li>
										<li className={'list-group-item ' + classes.planBenefitItem}>Save and resue recognition templates</li>
										<li className={'list-group-item ' + classes.planBenefitItem}>Export data as XLS, CSV, JSON</li>
										<li className={'list-group-item ' + classes.planBenefitItem}>User interface and API access</li>
										<li className={'list-group-item ' + classes.planBenefitItem}>Max {sliderValue} pages processed monthly</li>
										<li className={'list-group-item ' + classes.planBenefitItem}>24h email support</li>
									</ul>
								</div>
								<div className="mbr-section-btn text-center py-4 pb-5">
									<a href="/register" className="btn btn-primary display-4">
										GET STARTED
									</a>
								</div>
							</div>
						</div>

						{/* TEXT PROCESSING */}
						<div className={'plan col-12 mx-2 my-2 justify-content-center col-lg-6 ' + classes.planContainer}>
							<div className="plan-header text-center pt-5">
								<Box className={classes.iconTop}>
									<i className={clsx('icofont-optic', commonStyles.iconTitle48)}></i>
								</Box>
								<h3 className={'plan-title mbr-fonts-style display-5 ' + classes.planTitle}>Text Processing {sliderValue}</h3>
								<div className="plan-price" style={{ marginTop: theme.spacing(4) }}>
									<span className="price-value mbr-fonts-style display-5">$</span>
									<span className="price-figure mbr-fonts-style display-1">{tpPrice}</span>
								</div>
							</div>
							<div className="plan-body">
								<div className="plan-list align-center">
									<ul className="list-group list-group-flush mbr-fonts-style display-7">
										<li className={'list-group-item ' + classes.planBenefitItem}>
											<span style={{ backgroundColor: 'transparent', fontSize: '1rem' }}>
												{!checkedAnnual ? '/ month' : '/ year'}
												<br />
												<br />
												Make scanned PDF searchable
											</span>
											<br />
										</li>
										<li className={'list-group-item ' + classes.planBenefitItem}>Extract tables from PDFs and images</li>
										<li className={'list-group-item ' + classes.planBenefitItem}>Export tables as XLS, CSV, JSON</li>
										<li className={'list-group-item ' + classes.planBenefitItem}>Extract text from PDF</li>
										<li className={'list-group-item ' + classes.planBenefitItem}>Create HTML from PDF content</li>
										<li className={'list-group-item ' + classes.planBenefitItem}>User interface and API access</li>
										<li className={'list-group-item ' + classes.planBenefitItem}>Max {sliderValue} pages processed monthly</li>
										<li className={'list-group-item ' + classes.planBenefitItem}>24h email support</li>
									</ul>
								</div>
								<div className="mbr-section-btn text-center py-4 pb-5">
									<a href="/register" className="btn btn-primary display-4">
										GET STARTED
									</a>
								</div>
							</div>
						</div>

						{/* LIFE SCIENCES */}
						{/* <div className={'plan col-12 mx-2 my-2 justify-content-center col-lg-4 ' + classes.planContainer}>
							<div className="plan-header text-center pt-5">
								<h3 className={'plan-title mbr-fonts-style display-5 ' + classes.planTitle}>Life Sciences</h3>
								<div className="plan-price" style={{ marginTop: theme.spacing(4) }}>
									<span className="price-value mbr-fonts-style display-5">$</span>
									<span className="price-figure mbr-fonts-style display-1">{lsPrice}</span>
								</div>
							</div>
							<div className="plan-body">
								<div className="plan-list align-center">
									<ul className="list-group list-group-flush mbr-fonts-style display-7">
										<li className={'list-group-item ' + classes.planBenefitItem}>
											<span style={{ backgroundColor: 'transparent', fontSize: '1rem' }}>
												{!checkedAnnual ? '/ month' : '/ year'}
												<br />
												<br />
												Chemical molecule extraction from PDFs and images
											</span>
											<br />
										</li>
										<li className={'list-group-item ' + classes.planBenefitItem}>Export molecules as MOL, CML, SDF, SMILES</li>
										<li className={'list-group-item ' + classes.planBenefitItem}>User interface and API access</li>
										<li className={'list-group-item ' + classes.planBenefitItem}>Includes UNLIMITED pages processed monthly</li>
										<li className={'list-group-item ' + classes.planBenefitItem}>24h email support</li>
									</ul>
								</div>
								<div className="mbr-section-btn text-center py-4 pb-5">
									<a href="/register" className="btn btn-primary display-4">
										GET STARTED
									</a>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</section>
			<Footer />
		</React.Fragment>
	);
}

import { AxiosResponse } from 'axios';
import shortid from 'shortid';
import { BaseApiService } from './BaseApiService';
import { IBADocumentModel } from '../models/1-business-automation/IBADocumentModel';
import { IThumbnail } from 'models/IThumbnail';
import { PdfService } from './PdfService';
import { ICoordinate } from 'models/tables/ICoordinate';
import { IPage } from 'models/tables/IPage';
import { IBAField } from 'models/1-business-automation/IBAField';
import { IBAFieldType } from 'models/1-business-automation/IBAFieldType';
import { IInvoiceTemplateModel } from 'models/1-business-automation/IInvoiceTemplate';
import { ITable } from 'models/tables/ITable';

export class InvoiceService extends BaseApiService {
	public pdfService: PdfService;

	/**
	 * Constructor
	 */
	constructor() {
		super();
		this.pdfService = new PdfService();
	}
	// ************************************************************************************************************************************************************
	// UPLOAD invoice
	// ************************************************************************************************************************************************************
	/**
	 * GET
	 * Retrieves all invoices for the caller user (max. 1000)
	 *
	 * @return {*}  {Promise<IBADocumentModel[]>}
	 * @memberof InvoiceService
	 */
	public async getInvoices(): Promise<IBADocumentModel[]> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/invoices';
			const results: AxiosResponse<IBADocumentModel[]> = await this.axiosInstance.get<IBADocumentModel[]>(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	// ************************************************************************************************************************************************************
	// UPLOAD invoice
	// ************************************************************************************************************************************************************
	/**
	 * UPLOAD
	 * Uploads one invoice file. The file can be PDF and an image file too.
	 *
	 * @param {*} file
	 * @return {*}  {Promise<IBADocumentModel>}
	 * @memberof InvoiceService
	 */
	public async upload(file: any): Promise<IBADocumentModel> {
		try {
			const fd = new FormData();
			fd.append('file', file);
			const url = 'v1.0/api/business-automation/invoice-processing/upload';
			const results: AxiosResponse<IBADocumentModel> = await this.axiosInstance.post<IBADocumentModel>(url, fd, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	// ************************************************************************************************************************************************************
	// GET 1 INVOICE
	// ************************************************************************************************************************************************************
	/**
	 * INVOICES - GET 1
	 *
	 * Getting one particular pdf file descriptor from the mongo together with their thumbnails
	 *
	 * @param {string} id
	 * @return {*}  {Promise<ITablesModel>}
	 * @memberof TablesService
	 */
	public async getModel(id: string): Promise<IBADocumentModel> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/get/' + id;
			const results: AxiosResponse<IBADocumentModel> = await this.axiosInstance.get(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	// ************************************************************************************************************************************************************
	// GET INVOICE PAGE PREVIEW
	// ************************************************************************************************************************************************************
	/**
	 *
	 * PAGE PREVIEW - GET 1 PAGE PREVIEW
	 *
	 * @param {string} modelId
	 * @return {*}  {Promise<IThumbnail>}
	 * @memberof TablesService
	 */
	public async getPagePreview(modelId: string, pageIndex: number): Promise<IThumbnail> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/preview/get/' + modelId + '/' + pageIndex;
			const results: AxiosResponse<IThumbnail> = await this.axiosInstance.get<IThumbnail>(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	// ************************************************************************************************************************************************************
	// GET INVOICE FIELDS
	// ************************************************************************************************************************************************************
	/**
	 * Getting the available default field types from the database
	 *
	 * @return {*}  {Promise<ITablesModel>}
	 * @memberof TablesService
	 */
	public async getCommonFieldTypes(): Promise<IBAFieldType[]> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/field-types';
			const results: AxiosResponse<IBAFieldType[]> = await this.axiosInstance.get(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}
	// ************************************************************************************************************************************************************
	// EXTRACTION
	// ************************************************************************************************************************************************************
	/**
	 * Extract the text from the page part defined by the given coordinates
	 *
	 * @param {string} modelId
	 * @param {number} pageNumber
	 * @param {ICoordinate[]} coords
	 * @return {*}  {Promise<IBADocumentModel>}
	 * @memberof InvoiceService
	 */
	public async extractPageParts(modelId: string, pageNumber: number, coords: ICoordinate[]): Promise<IBADocumentModel> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/extract/parts';
			const data = {
				modelId: modelId,
				pageNumber: pageNumber,
				coordinates: JSON.stringify(coords),
			};
			const results: AxiosResponse<IBADocumentModel> = await this.axiosInstance.post(url, data, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 * Extract the text from the page part defined by the given coordinate
	 *
	 * @param {string} modelId
	 * @param {number} pageNumber
	 * @param {ICoordinate} coord
	 * @return {*}  {Promise<IBADocumentModel>}
	 * @memberof InvoiceService
	 */
	public async extractPagePart(modelId: string, pageNumber: number, coord: ICoordinate): Promise<IBAField> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/extract/part';
			const data = {
				modelId: modelId,
				pageNumber: pageNumber,
				coordinate: JSON.stringify(coord),
			};
			const results: AxiosResponse<IBAField> = await this.axiosInstance.post(url, data, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	// ************************************************************************************************************************************************************
	// UPDATE FIELDS
	// ************************************************************************************************************************************************************
	/**
	 * Updates the fields of the given model
	 *
	 * @param {string} modelId
	 * @param {IBAField[]} fields
	 * @return {*}  {Promise<boolean>}
	 * @memberof InvoiceService
	 */
	public async updateFields(modelId: string, fields: IBAField[]): Promise<boolean> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/update/fields';
			const data = {
				modelId: modelId,
				fields: fields,
			};
			const results: AxiosResponse<boolean> = await this.axiosInstance.post(url, data, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async updateField(modelId: string, field: IBAField): Promise<IBAField> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/update/field';
			const data = {
				modelId: modelId,
				field: field,
			};
			const results: AxiosResponse<IBAField> = await this.axiosInstance.post(url, data, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async updateCustomFieldType(modelId: string, fieldType: IBAFieldType): Promise<IBAFieldType> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/update/custom-field-type';
			const data = {
				modelId: modelId,
				fieldType: fieldType,
			};
			const results: AxiosResponse<IBAFieldType> = await this.axiosInstance.post(url, data, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	// ************************************************************************************************************************************************************
	// DOWNLOAD
	// ************************************************************************************************************************************************************
	public async downloadResultFile(modelIds: string[], type: string): Promise<any> {
		try {
			if (!modelIds || modelIds.length < 1) throw new Error('Model ids are not provided');
			const url = 'v1.0/api/business-automation/invoice-processing/download/' + type + '/' + modelIds.join(',');
			const configOptions = this.getAuthHeader(this.accessToken);
			// const fileDownload = require('js-file-download');
			// this.axiosInstance.get(url, configOptions).then...
			fetch(this.myBaseURL + url, configOptions).then((response) => {
				// fileDownload(response.data, 'ocr.pdf');
				response.blob().then((blob) => {
					const url2 = window.URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.href = url2;
					if (modelIds.length == 1) link.setAttribute('download', type + '_results.' + type);
					else link.setAttribute('download', type + '_results_for_multiple_docs.zip');
					document.body.appendChild(link);
					link.click();
					link.remove();
				});
			});
			// return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async downloadMultipleResultFiles(modelIds: string[], type: string): Promise<any> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/download/multiple/' + type;
			const data = {
				modelIds: modelIds,
				type: type,
			};
			const results: AxiosResponse<IBADocumentModel[]> = await this.axiosInstance.post(url, data, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	// ************************************************************************************************************************************************************
	// TEMPLATE
	// ************************************************************************************************************************************************************
	public async applyTemplate(modelIds: string[], template: IInvoiceTemplateModel): Promise<IBADocumentModel[]> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/template/apply';
			const data = {
				modelIds: modelIds,
				template: template,
			};
			const results: AxiosResponse<IBADocumentModel[]> = await this.axiosInstance.post(url, data, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async listTemplates(): Promise<IInvoiceTemplateModel[]> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/template/list';
			const results: AxiosResponse<IInvoiceTemplateModel[]> = await this.axiosInstance.get(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async createTemplate(template: IInvoiceTemplateModel): Promise<IInvoiceTemplateModel> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/template/create';
			const data = {
				template: template,
			};
			const results: AxiosResponse<IInvoiceTemplateModel> = await this.axiosInstance.post(url, data, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async createTemplateFromModel(modelId: string, name: string): Promise<IInvoiceTemplateModel> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/template/create-from-model';
			const data = {
				modelId: modelId,
				templateName: name,
			};
			const results: AxiosResponse<IInvoiceTemplateModel> = await this.axiosInstance.post(url, data, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async downloadInvoiceTemplate(model: IInvoiceTemplateModel): Promise<any> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/template/download/' + model._id;
			const configOptions = this.getAuthHeader(this.accessToken);
			fetch(this.myBaseURL + url, configOptions).then((response) => {
				response.blob().then((blob) => {
					const url2 = window.URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.href = url2;
					link.setAttribute('download', model.name + '.wtmpl');
					document.body.appendChild(link);
					link.click();
					link.remove();
				});
			});
			// return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async deleteTemplate(modelId: string): Promise<boolean> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/template/delete/' + modelId;
			const results: AxiosResponse<boolean> = await this.axiosInstance.delete(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	// ************************************************************************************************************************************************************
	// LINE ITEMS - Extract
	// ************************************************************************************************************************************************************
	public async extractTable(modelId: string, pageNumber: number, coords: ICoordinate[]): Promise<ITable> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/extract/table';
			const data = {
				modelId: modelId,
				pageNumber: pageNumber,
				coordinates: JSON.stringify(coords),
			};
			const results: AxiosResponse<ITable> = await this.axiosInstance.post(url, data, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	// ************************************************************************************************************************************************************
	// DELETE
	// ************************************************************************************************************************************************************
	/**
	 * Deletes a file from the cloud storage and from the DB. Permanently.
	 *
	 * @param {string} id
	 * @return {*}  {Promise<void>}
	 * @memberof InvoiceService
	 */
	public async delete(id: string): Promise<void> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/delete/' + id;
			await this.axiosInstance.delete(url, this.apiConfig);
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 * Deletes a field from the give model.
	 *
	 * @param {string} modelId
	 * @param {string} fieldId
	 * @return {*}  {Promise<void>}
	 * @memberof InvoiceService
	 */
	public async deleteField(modelId: string, fieldId: string): Promise<void> {
		try {
			const url = 'v1.0/api/business-automation/invoice-processing/delete/' + modelId + '/field/' + fieldId;
			await this.axiosInstance.delete(url, this.apiConfig);
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	// ************************************************************************************************************************************************************
	// HELPER methods
	// ************************************************************************************************************************************************************
	public calculateImageCoordinates(model: IBADocumentModel, pageNumber: number, width: number, height: number, generateId: boolean): ICoordinate[] {
		const page: IPage = model.pages.find((pg) => {
			return pg.index === pageNumber - 1;
		});

		const tmpKeyDatas: IBAField[] = model.fields.filter((keyData: IBAField) => {
			return keyData.pageIndex === pageNumber;
		});
		const tmpCoordinates = [];
		for (let index = 0; index < tmpKeyDatas.length; index++) {
			const keyData: IBAField = tmpKeyDatas[index];
			tmpCoordinates.push({
				x: this.pdfService.fromPdfToImage(width, page.width, keyData.x), // x_pdf * width_image / width_page
				y: this.pdfService.fromPdfToImage(height, page.height, keyData.y), // (height_pdf - y_pdf) * height_image / height_pdf
				width: this.pdfService.fromPdfToImage(width, page.width, keyData.width),
				height: this.pdfService.fromPdfToImage(height, page.height, keyData.height),
				id: generateId === true ? shortid.generate() : keyData._id,
			});
		}
		return tmpCoordinates;
	}
}

import React, { FC } from 'react';
import { Box, Button, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import { Link as RouterLink } from 'react-router-dom';
import { NavigationLinks } from 'models/NavigationLinks';

const useStyles = makeStyles({
	fullWidth: {
		width: '100%',
	},
	lockIcon: {
		fontSize: 240,
		opacity: 0.2,
	},
});

export interface IDontHaveAccessProps {
	// title: string;
	// description: string;
}

export const DontHaveAccess: FC<IDontHaveAccessProps> = (props: IDontHaveAccessProps) => {
	const classes = useStyles();

	// const { title, description } = props;
	return (
		<React.Fragment>
			<Container>
				<Grid container direction="column" alignItems="center" justify="center">
					<Grid item xs={12}>
						<Box>
							<LockTwoToneIcon className={classes.lockIcon} />
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Box mt={6}>
							<Typography variant="h3" align="center">
								Access Restricted
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Box mt={3} style={{ width: '500px' }}>
							<Typography variant="body1" align="center">
								Ooops. This area needs a subscription. It seems you don't have the appropriate one. I recommend you to go and check our plans and subscribe so you can access this page.
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Box mt={6}>
							<Button variant="contained" component={RouterLink} to={NavigationLinks.PRICING} color="primary" type="submit" fullWidth={true} style={{ height: '58px', fontSize: '24px' }}>
								Let's subscribe
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Container>

			{/* <Grid container></Grid>
			<Box display="flex" alignItems="center" justifyContent="center" m={0} p={4} className={classes.fullWidth}>
				<Box className={classes.fullWidth}>
					<Typography variant="h5" align="left">
						You don't have access
					</Typography>
				</Box>
				<Typography align="left" variant="body2" gutterBottom>
					Go and subscribe
				</Typography>
			</Box> */}
		</React.Fragment>
	);
};

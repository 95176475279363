import Cookies from "universal-cookie";

export class CookieService {
	private cookies: Cookies;
	/**
	 *
	 */
	constructor() {
		this.cookies = new Cookies();
	}

	public setCookie(name: string, value: string, options?: object) {
		let opts: object = {};
		if (options) {
			opts = options;
		}
		this.cookies.set(name, value, opts);
	}

	public getCookie(name: string, options?: object): any {
		let opts: object = {};
		if (options) {
			opts = options;
		}
		return this.cookies.get(name, opts);
	}

	public removeCookie(name: string, options?: object): any {
		let opts: object = {};
		if (options) {
			opts = options;
		}
		return this.cookies.remove(name, opts);
	}
}

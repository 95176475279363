export enum MessageType {
	PRIMARY = 'Primary',
	SECONDARY = 'Secondary',
	ERROR = 'Error',
	WARNING = 'Warning',
	INFO = 'Info',
	SUCCESS = 'Success',
}

export interface IMessagePlaceHolder {
	visible: boolean;
	message: string;
	type: MessageType;
}

import React, { useEffect, useLayoutEffect } from 'react';
import { makeStyles, Grid, AppBar, Toolbar, Typography, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// import { MyAppContext } from 'AppContext';
import { AppStore, IAppStoreModel } from 'models/AppStore';
import { MessageType } from 'models/IMessagePlaceHolder';

const useStyles = makeStyles((theme) => ({
	root: {
		//flexGrow: 1,
	},
	appBarPrimary: {
		backgroundColor: theme.palette.primary.dark,
	},
	appBarSecondary: {
		backgroundColor: theme.palette.secondary.dark,
	},
	appBarError: {
		backgroundColor: theme.palette.error.dark,
	},
	appBarWarning: {
		backgroundColor: theme.palette.warning.dark,
	},
	appBarInfo: {
		backgroundColor: theme.palette.info.dark,
	},
	appBarSuccess: {
		backgroundColor: theme.palette.success.dark,
	},
	title: {
		flexGrow: 1,
		textAlign: 'center',
		color: theme.palette.getContrastText(theme.palette.error.dark),
	},
}));

export const MessagePlaceHolder: React.FC = () => {
	const messages = AppStore.useState((s: IAppStoreModel) => s.messages);
	const classes = useStyles();
	const messageTimeout: number = 20000;
	let bgClass = classes.appBarInfo;

	switch (messages.type) {
		case MessageType.PRIMARY:
			bgClass = classes.appBarPrimary;
			break;
		case MessageType.SECONDARY:
			bgClass = classes.appBarSecondary;
			break;
		case MessageType.ERROR:
			bgClass = classes.appBarError;
			break;
		case MessageType.WARNING:
			bgClass = classes.appBarWarning;
			break;
		case MessageType.INFO:
			bgClass = classes.appBarInfo;
			break;
		case MessageType.SUCCESS:
			bgClass = classes.appBarSuccess;
			break;
		default:
			break;
	}

	function onClose() {
		AppStore.update((s: IAppStoreModel) => {
			s.messages.visible = false;
		});
	}

	useEffect(() => {
		// When the message is visible then let's hide it after period of time
		if (messages.visible) {
			setTimeout(() => {
				AppStore.update((s: IAppStoreModel) => {
					s.messages.visible = false;
				});
			}, messageTimeout);
		}
	}, [messages]);

	return (
		<React.Fragment>
			{messages.visible && (
				<div className={classes.root}>
					<AppBar position="fixed" className={bgClass}>
						<Toolbar>
							<Typography variant="h6" className={classes.title}>
								{messages.message}
							</Typography>
							<IconButton color="inherit" onClick={() => onClose()}>
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</AppBar>
				</div>
			)}
		</React.Fragment>
	);
};

import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { CookieService } from './CookieService';
import { HelperService } from './HelperService';
import { SessionStorageService } from './SessionStorageService';

export class BaseApiService {
	protected apiConfig: AxiosRequestConfig;
	protected axiosInstance: AxiosInstance;
	protected myBaseURL: string;
	protected accessToken: string;
	protected helperService: HelperService = new HelperService();
	/**
	 *
	 */
	constructor() {
		this.myBaseURL = process.env.REACT_APP_API_URL || '';

		this.accessToken = new SessionStorageService<any>().get('access_token');
		// this.accessToken = new CookieService().getCookie('access_token', { path: '/' });

		this.apiConfig = {
			headers: {
				// 'Content-Type': 'multipart/form-data',
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + this.accessToken,
			},
		};

		this.axiosInstance = axios.create({
			baseURL: this.myBaseURL,
			timeout: parseInt(process.env.REACT_APP_API_TIMEOUT),
		});
	}

	public getAuthHeader(accessToken: string) {
		return {
			headers: {
				Authorization: 'Bearer ' + accessToken,
			},
		};
	}
}

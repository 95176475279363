export class PdfService {
	public fromPdfToImage(imageSize: number, pdfPageSize: number, pdfCoord: number): number {
		const imgCoord = pdfCoord * (imageSize / pdfPageSize);
		return imgCoord;
	}

	public fromImageToPdf(imageSize: number, pdfPageSize: number, imgCoord: number): number {
		const pdfCoord = imgCoord * (pdfPageSize / imageSize);
		return pdfCoord;
	}
}

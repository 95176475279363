import { IImageModel } from 'models/IImageModel';
import { IFileModel } from 'models/IFileModel';
import { ProcessingStatus } from 'models/ProcessingStatus';
import { IThumbnail } from 'models/IThumbnail';

export class ModelService {
	public createEmptyFileModel(): IFileModel {
		return {
			_id: '',
			countOfImagesFound: 0,
			countOfImagesProcessed: 0,
			totalPageCount: 0,
			createDate: new Date(Date.now()),
			error: null,
			extension: '',
			thumbnails: null,
			images: null,
			jobId: '',
			lastModificationDate: new Date(Date.now()),
			mimeType: '',
			originalFileName: '',
			processingTime: '',
			size: 0,
			status: ProcessingStatus.NOT_STARTED,
		};
	}

	public createEmptyImageModel(): IImageModel {
		return {
			_id: '',
			base64: '',
			createDate: new Date(Date.now()),
			lastModificationDate: new Date(Date.now()),
			error: null,
			imageNumber: 0,
			pageNumber: 0,
			mol: '',
			status: ProcessingStatus.NOT_STARTED,
		};
	}

	public createEmptyThumbnailModel(): IThumbnail {
		return {
			_id: '',
			imagePath: '',
			base64: '',
			pageNumber: 0,
			createDate: new Date(Date.now()),
			lastModificationDate: new Date(Date.now()),
		};
	}
}

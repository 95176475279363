import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { CopyRight } from '../footer/CopyRight';
import { Services } from '../../services/Services';
import { useGlobalState } from '../../models/GlobalStates';
import { useHistory } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { NavigationLinks } from 'models/NavigationLinks';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		padding: theme.spacing(2, 0, 2),
	},
}));

const PasswordReset: React.FC = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const services: Services = new Services();
	const [email, setEmail] = useState('your@email.com');
	const [showFeedback, setShowFeedback] = useState(false);

	function validateForm() {
		return services.helperService.validateEmail(email);
	}

	const handleSubmit = async (event: any) => {
		try {
			event.preventDefault();
			setShowFeedback(true);
			const result = await services.userService.sendResetPasswordLink(email);
		} catch (error) {}
	};

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<a href="/">
					<img src="/assets/images/logo.svg" alt="walheda.com" title="Walheda" style={{ height: '3.8rem' }} />
				</a>

				<Typography component="h1" variant="h5" style={{ paddingTop: '50px' }}>
					PASSWORD RESET
				</Typography>
				<form className={classes.form} onSubmit={handleSubmit}>
					<TextField variant="outlined" margin="normal" required fullWidth id="email" label="Registered email" name="email" autoComplete="email" placeholder={email} onChange={(e) => setEmail(e.target.value)} />
					<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} disabled={!validateForm()}>
						RESET MY PASSWORD
					</Button>
				</form>
			</div>
			{showFeedback && (
				<Box mt={4} display="flex" justifyContent="center" style={{ textAlign: 'center' }}>
					An email is sent to your email address with the instructions for password reset.
				</Box>
			)}
			<Box mt={4}>
				<CopyRight />
			</Box>
			<Box display="flex" justifyContent="center" m={3}>
				<Link href={NavigationLinks.SIGN_IN} style={{ textDecoration: 'none' }}>
					BACK TO LOGIN
				</Link>
			</Box>
		</Container>
	);
};

export default PasswordReset;

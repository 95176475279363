import { React, Grid, Container, useEffect, Services, Box } from '../globals/GlobalIncludes';
import { Footer } from './Footer';

export default function BusinessAutomation() {
	const services: Services = new Services();

	useEffect(() => {}, []);

	return (
		<React.Fragment>
			<Grid container>
				<Grid item xs={12} style={{ marginTop: '80px' }}>
					<section className="mbr-section content4 cid-s6OsovoJvu" id="content4-1d">
						<div style={{ maxWidth: '1200px', margin: 'auto' }}>
							<Grid container>
								<Grid item xs={12} md={8}>
									<div className="media-container-row">
										<div className="title col-12 col-md-8">
											<h2 className="align-center pb-3 mbr-fonts-style display-1">
												<strong>BUSINESS AUTOMATION</strong>
											</h2>
											<h3 className="mbr-section-subtitle align-center mbr-light mbr-fonts-style display-5">Extract context aware value with its meaning from documents automatically.</h3>
										</div>
									</div>
									<Box mt={1} display="flex" justifyContent="center">
										<div className="mbr-section-btn align-right py-4">
											<a className="btn btn-primary display-4" href="/register">
												GET STARTED
											</a>
										</div>
									</Box>
								</Grid>
								<Grid item xs={12} md={4}>
									<img src="/assets/images/ba/data_extraction_2.svg" alt="Business Automation" title="Business Automation" width="90%" />
								</Grid>
							</Grid>
						</div>
					</section>

					<section className="progress-bars3 cid-s6Ot5JsTCE" id="progress-bars3-1e">
						<div className="container">
							<h2 className="mbr-section-title pb-3 align-center mbr-fonts-style display-2">
								<strong>Extract Valuable Data From Documents</strong>
							</h2>
							<h3 className="mbr-section-subtitle mbr-fonts-style display-5">
								Our AI powered data extraction service makes the data extraction template creation unnecessary. The key-value pairs (value-meaning pairs) and tables are detected and extracted automatically by the AI engine. If you feel like
								using a template based approach, don't worry. You can create, save and reuse templates too. What is more, you can combine the template based extraction with the AI based detection! The decision is in your hand and the results
								will be awesome.
							</h3>
							<div className="container" style={{ marginTop: '48px' }}>
								<div className="row justify-content-center content-row">
									<img src="/assets/images/BusinessAutomationDemo.gif" width="80%" height="auto" alt="Business Automation" />
								</div>
							</div>
						</div>
					</section>

					<section className="features15 cid-sDVzz2IiwV" id="features16-6" style={{ paddingTop: '48px', paddingBottom: '48px' }}>
						<div className="container">
							<div className="content-wrapper">
								<div className="row align-items-center">
									<div className="col-12 col-lg">
										<div className="text-wrapper">
											<h6 className="card-title mbr-fonts-style display-5">
												<strong>AI Based Detection</strong>
											</h6>
											<p className="mbr-text mbr-fonts-style mb-4 display-4">
												It's part of what we do. With AI based data detection you'll not need to pre-define extraction templates. You don't need to know the document structure upfront. The AI engine will detect the document structure on
												its own. AI can detect tables and key-value pairs automatically. Key value pairs mean like the 'INVOICE NUMBER' title and its VALUE (01/13/2021 for example). This approach works best if you have many differently
												structured input documents.
											</p>
										</div>
									</div>
									<div className="col-12 col-lg-6">
										<div className="image-wrapper" style={{ textAlign: 'center' }}>
											<img src="/assets/images/ba/BA_AI.svg" alt="Yeee" width="50%" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="features16 cid-sDVEAKUTxW" id="features17-7" style={{ paddingTop: '48px', paddingBottom: '48px' }}>
						<div className="container">
							<div className="content-wrapper">
								<div className="row align-items-center">
									<div className="col-12 col-lg-6">
										<div className="image-wrapper" style={{ textAlign: 'center', paddingBottom: '24px' }}>
											<img src="/assets/images/ba/ba_template.svg" alt="Walheda" width="50%" />
										</div>
									</div>
									<div className="col-12 col-lg">
										<div className="text-wrapper">
											<h6 className="card-title mbr-fonts-style display-5">
												<strong>Template Based Extraction</strong>
											</h6>
											<p className="mbr-text mbr-fonts-style mb-4 display-4">
												It's part of what we do. With template based detection you are required to create an extraction template first. This means drawing rectangles around the desired values on the document preview. Then you'll need to
												set the type of value you have just highlighted (llike INVOICE NUMBER). Templates can be saved and applied for any other documents. This approach works best if you have many documents with the same structure.
												Disclaimer: template based recognition use Zonal OCR technology and can provide different results compared to the AI approach.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="features15 cid-sDVzz2IiwV" id="features16-6" style={{ paddingTop: '48px', paddingBottom: '48px' }}>
						<div className="container">
							<div className="content-wrapper">
								<div className="row align-items-center">
									<div className="col-12 col-lg">
										<div className="text-wrapper">
											<h6 className="card-title mbr-fonts-style display-5">
												<strong>Combined Extraction (AI + Template)</strong>
											</h6>
											<p className="mbr-text mbr-fonts-style mb-4 display-4">
												It's what we do! With the combined approach the AI engine detects the key-value pairs and tables in the document, but you have the opportunity to define new fields (like with templates). This approach is the most
												accurate since the fields are detected automatically, but you can easily correct the errors if any or specify new fields.
											</p>
										</div>
									</div>
									<div className="col-12 col-lg-6">
										<div className="image-wrapper" style={{ textAlign: 'center' }}>
											<img src="/assets/images/ba/ba_together.svg" alt="Yeee" width="50%" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="features1 cid-s6OmBO46mA" id="features1-1b">
						<div className="container">
							<div className="media-container-row">
								<div className="card p-3 col-12 col-md-6 col-lg-4">
									<div className="card-img pb-3">
										<img src="/assets/images/ba/ba_key_value.svg" alt="Yeee" width="30%" />
									</div>
									<div className="card-box">
										<h4 className="card-title py-3 mbr-fonts-style display-5">Value-Meaning pair extraction</h4>
										<p className="mbr-text mbr-fonts-style display-7">
											Key-value pairs (Value-meaning pairs) are natural part of many types of documents (invoices, sales orders, contracts, product catalogs, tax forms, etc). Walheda.com automatically detects the keys in the documents and
											extracts the corresponding value for the key. The extraction is based on an AI engine under the hood.
										</p>
									</div>
								</div>
								<div className="card p-3 col-12 col-md-6 col-lg-4">
									<div className="card-img pb-3">
										<img src="/assets/images/ba/ba_table_extraction.svg" alt="Yeee" width="30%" />
									</div>
									<div className="card-box">
										<h4 className="card-title py-3 mbr-fonts-style display-5">Embedded table extraction</h4>
										<p className="mbr-text mbr-fonts-style display-7">
											Invoices contain line items. Product lists contain product rows. They are embedded in documents. Our AI engine detects them and extracts them keeping their tabular format.
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="header1 cid-s6J2zO1MYK mbr-parallax-background" id="header16-9">
						<div className="mbr-overlay" style={{ opacity: '0.9', backgroundColor: 'rgb(255, 255, 255)' }}></div>
						<div className="container">
							<div className="row justify-content-md-center">
								<div className="col-md-10 align-center">
									<h1 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">SIGN UP FOR FREE</h1>
									<h3 className="mbr-section-subtitle mbr-light pb-3 mbr-fonts-style display-2">No credit card required</h3>
									<p className="mbr-text pb-3 mbr-fonts-style display-5">
										Create your free account and test. Minimize the manual work.
										<br />
										Our customers love to save hours with us. <strong>Be one of them.</strong>
									</p>
								</div>
								<Box mt={1} display="flex" justifyContent="center" alignItems="center" style={{ margin: 'auto' }}>
									<div className="mbr-section-btn align-right py-4">
										<a className="btn btn-primary display-4" href="/register">
											GET STARTED
										</a>
									</div>
								</Box>
							</div>
						</div>
					</section>
				</Grid>
			</Grid>
			<Footer />
		</React.Fragment>
	);
}

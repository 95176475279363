import React, { Suspense } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Navigator from './Navigator';
import Header from './Header';
import { CopyRight } from 'components/footer/CopyRight';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { CircularProgress, Box } from '@material-ui/core';
import { NavigationLinks } from 'models/NavigationLinks';

const Dashboard = React.lazy(() => import('../dashboard/Dashboard'));
const ContentList = React.lazy(() => import('../life-science/osr/ContentList'));
const TableParserList = React.lazy(() => import('components/2-text-processing/tables/TableParserList'));
const ViewDocument = React.lazy(() => import('components/2-text-processing/tables/ViewDocument'));
const StructureView = React.lazy(() => import('../life-science/osr/StructureView'));
const PdfView = React.lazy(() => import('../life-science/osr/PdfView'));
const PdfItemView = React.lazy(() => import('../life-science/osr/PdfItemView'));
const MyProfile = React.lazy(() => import('../profile/MyProfile'));
const Organization = React.lazy(() => import('../organization/Organization'));
const Pricing = React.lazy(() => import('../subscriptions/Pricing'));
const SubscriptionsSummary = React.lazy(() => import('../subscriptions/SubscriptionsSummary'));
const PaySubscription = React.lazy(() => import('../subscriptions/PaySubscription'));
const Ocr = React.lazy(() => import('../2-text-processing/ocr/Ocr'));
const ApiDocsTextProcessing = React.lazy(() => import('../2-text-processing/ApiDocsTextProcessing'));
const ApiDocsBusinessAutomation = React.lazy(() => import('../1-business-automation/ApiDocsBusinessAutomation'));
const InvoicesList = React.lazy(() => import('../1-business-automation/InvoicesList'));
const InvoiceEditDocument = React.lazy(() => import('../1-business-automation/EditDocument'));
// Admin
const LogsPage = React.lazy(() => import('../admin/Logs'));
const UsersList = React.lazy(() => import('../admin/UsersList'));

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		minHeight: '100vh',
	},
	drawer: {
		// [theme.breakpoints.up('sm')]: {
		// width: 73,
		flexShrink: 0,
		// },
	},
	app: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	main: {
		flex: 1,
		padding: theme.spacing(6, 4),
		background: '#fff',
	},
	footer: {
		padding: theme.spacing(2),
		background: '#eaeff1',
	},
}));

export interface HomeProps {}

function Home(props: HomeProps) {
	const classes = useStyles();
	const [mobileOpen, setMobileOpen] = React.useState(true);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	return (
		<React.Fragment>
			<Suspense
				fallback={
					<Box display="flex" alignItems="center" justifyContent="center" m={0} p={4}>
						<CircularProgress disableShrink />
					</Box>
				}
			>
				<BrowserRouter>
					<div className={classes.root}>
						<CssBaseline />
						<nav className={classes.drawer}>
							{/* <Hidden smUp implementation="js">
								<Navigator PaperProps={{ style: { width: 256 } }}  open={mobileOpen} onClose={handleDrawerToggle} />
							</Hidden>
							<Hidden xsDown implementation="css">
								<Navigator PaperProps={{ style: { width: 256 } }} />
							</Hidden> */}
							<Navigator PaperProps={{}} open={mobileOpen} onClose={handleDrawerToggle} />
						</nav>
						<div className={classes.app}>
							<Header onDrawerToggle={handleDrawerToggle} />
							<main className={classes.main}>
								<Switch>
									<Route exact={true} path={NavigationLinks.APP} children={<Dashboard />} />
									<Route exact={true} path={NavigationLinks.TEXT_PROCESSING_OCR} children={<Ocr />} />
									<Route exact={true} path={NavigationLinks.BUSINESS_AUTOMATION_INVOICES} children={<InvoicesList />} />
									<Route exact={true} path={NavigationLinks.BUSINESS_AUTOMATION_INVOICES_API} children={<ApiDocsBusinessAutomation />} />
									<Route exact={true} path={NavigationLinks.BUSINESS_AUTOMATION_INVOICES_EDIT} children={<InvoiceEditDocument />} />
									<Route exact={true} path={NavigationLinks.LIFE_SCIENCE_MOLECULE_EXTRACTION} children={<ContentList />} />
									<Route exact={true} path={NavigationLinks.TEXT_PROCESSING_TABLES} children={<TableParserList />} />
									<Route exact={true} path={NavigationLinks.TEXT_PROCESSING_TABLES_VIEW_DOCUMENT} children={<ViewDocument />} />
									<Route exact={true} path={NavigationLinks.TEXT_PROCESSING_API} children={<ApiDocsTextProcessing />} />
									{/* <Route exact={true} path={NavigationLinks.LIFE_SCIENCE_MOLECULE_EXTRACTION_VIEW_IMAGE} children={<StructureView />} />
									<Route exact={true} path={NavigationLinks.LIFE_SCIENCE_MOLECULE_EXTRACTION_VIEW_PDF} children={<PdfView />} />
									<Route exact={true} path={NavigationLinks.LIFE_SCIENCE_MOLECULE_EXTRACTION_VIEW_PDF_ITEM} children={<PdfItemView />} /> */}
									<Route exact={true} path={NavigationLinks.MY_PROFILE} children={<MyProfile />} />
									<Route exact={true} path={NavigationLinks.ORGANIZATION} children={<Organization />} />
									<Route exact={true} path={NavigationLinks.PRICING} children={<Pricing />} />
									<Route exact={true} path={NavigationLinks.SUBSCRIPTIONS} children={<SubscriptionsSummary />} />
									<Route exact={true} path={NavigationLinks.SUBSCRIPTIONS_PAYMENT} children={<PaySubscription />} />
									<Route exact={true} path={NavigationLinks.API_KEY} children={<Organization />} />
									<Route exact={true} path={NavigationLinks.ADMIN_USERS} children={<UsersList />} />
									<Route exact={true} path={NavigationLinks.ADMIN_LOGS} children={<LogsPage />} />
								</Switch>
							</main>
							<footer className={classes.footer}>
								<CopyRight />
							</footer>
						</div>
					</div>
				</BrowserRouter>
			</Suspense>
		</React.Fragment>
	);
}

export default Home;

import { AxiosResponse } from 'axios';
import { IThumbnail } from 'models/IThumbnail';
import { ICoordinate } from 'models/tables/ICoordinate';
import { IPage } from 'models/tables/IPage';
import { ITable } from 'models/tables/ITable';
import { ITablesModel } from '../models/tables/ITablesModel';
import { BaseApiService } from './BaseApiService';
import { PdfService } from './PdfService';
import shortid from 'shortid';
import { ICell } from 'models/tables/ICell';

export class TablesService extends BaseApiService {
	public pdfService: PdfService;

	/**
	 * Constructor
	 */
	constructor() {
		super();
		this.pdfService = new PdfService();
	}
	/**
	 * EXTRACT - PDF
	 *
	 * Start the OCR conversion for the image based file
	 *
	 * @param {*} file
	 * @return {*}  {Promise<string>}
	 * @memberof TablesService
	 */
	public async extract(file: any): Promise<ITablesModel> {
		try {
			const fd = new FormData();
			fd.append('file', file);

			const url = 'v1.0/api/text-processing/tables/extract/start';
			const results: AxiosResponse<ITablesModel> = await this.axiosInstance.post(url, fd, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async extractPdfPart(modelId: string, pageNumber: number, coords: ICoordinate[]): Promise<ITablesModel> {
		try {
			const url = 'v1.0/api/text-processing/tables/extract/pdf/part';
			const data = {
				modelId: modelId,
				pageNumber: pageNumber,
				coordinates: JSON.stringify(coords),
			};
			const results: AxiosResponse<ITablesModel> = await this.axiosInstance.post(url, data, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 * FILES - GET MORE
	 *
	 * Getting all the files which the user uploaded for OCR processing regardless their processing statuses
	 *
	 * @return {*}  {Promise<IOcrFileModel[]>}
	 * @memberof TablesService
	 */
	public async getFiles(): Promise<ITablesModel[]> {
		try {
			const url = 'v1.0/api/text-processing/tables/files';
			const results: AxiosResponse<ITablesModel[]> = await this.axiosInstance.get<ITablesModel[]>(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 * FILE - GET 1
	 *
	 * Getting one particular pdf file descriptor from the mongo together with their thumbnails
	 *
	 * @param {string} id
	 * @return {*}  {Promise<ITablesModel>}
	 * @memberof TablesService
	 */
	public async getFile(id: string): Promise<ITablesModel> {
		try {
			const url = 'v1.0/api/text-processing/tables/files/get/' + id;
			const results: AxiosResponse<ITablesModel> = await this.axiosInstance.get(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	// ************************************************************************************************************************************************************
	// DOWNLOAD
	// ************************************************************************************************************************************************************
	public async downloadTablesFile(modelId: string, type: string): Promise<any> {
		try {
			if (!modelId) throw new Error('Model id is not provided');
			const url = 'v1.0/api/text-processing/tables/download/' + type + '/' + modelId;
			const configOptions = this.getAuthHeader(this.accessToken);
			// const fileDownload = require('js-file-download');
			// this.axiosInstance.get(url, configOptions).then...
			fetch(this.myBaseURL + url, configOptions).then((response) => {
				// fileDownload(response.data, 'ocr.pdf');
				response.blob().then((blob) => {
					const url2 = window.URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.href = url2;
					link.setAttribute('download', type + '_tables.' + type);
					document.body.appendChild(link);
					link.click();
					link.remove();
				});
			});
			// return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 * CELL - UPDATE 1
	 *
	 * The method updates one particular cell. The cell object should contain the modifications
	 *
	 * @param {string} tableId
	 * @param {string} rowId
	 * @param {ICell} cell
	 * @memberof TablesService
	 */
	public async updateCell(modelId: string, tableId: string, rowId: string, cell: ICell) {
		// The cell should contain the modifications
		try {
			const url = 'v1.0/api/text-processing/tables/update/cell';
			const data = {
				modelId,
				tableId,
				rowId,
				cell: JSON.stringify(cell),
			};
			const results: AxiosResponse<ITablesModel> = await this.axiosInstance.post(url, data, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 *
	 * THUMBNAILS - GET FOR 1
	 *
	 * @param {string} fileId
	 * @return {*}  {Promise<IThumbnail[]>}
	 * @memberof TablesService
	 */
	public async getThumbnails(fileId: string): Promise<IThumbnail[]> {
		try {
			const url = 'v1.0/api/text-processing/tables/files/thumbnails/list/' + fileId;
			const results: AxiosResponse<IThumbnail[]> = await this.axiosInstance.get<IThumbnail[]>(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 *
	 * THUMBNAIL - GET 1 THUMBNAIL
	 *
	 * @param {string} modelId
	 * @return {*}  {Promise<IThumbnail[]>}
	 * @memberof TablesService
	 */
	public async getThumbnail(modelId: string, thbId: string): Promise<IThumbnail> {
		try {
			const url = 'v1.0/api/text-processing/tables/files/thumbnails/get/' + modelId + '/' + thbId;
			const results: AxiosResponse<IThumbnail> = await this.axiosInstance.get<IThumbnail>(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 *
	 * PAGE PREVIEW - GET 1 PAGE PREVIEW
	 *
	 * @param {string} modelId
	 * @return {*}  {Promise<IThumbnail>}
	 * @memberof TablesService
	 */
	public async getPagePreview(modelId: string, pageIndex: number): Promise<IThumbnail> {
		try {
			const url = 'v1.0/api/text-processing/tables/files/preview/get/' + modelId + '/' + pageIndex;
			const results: AxiosResponse<IThumbnail> = await this.axiosInstance.get<IThumbnail>(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 * DELETE
	 *
	 * Deletes a file from the cloud storage and from the DB. Permanently.
	 *
	 * @param {string} id
	 * @return {*}  {Promise<void>}
	 * @memberof TablesService
	 */
	public async delete(id: string): Promise<void> {
		try {
			const url = 'v1.0/api/text-processing/tables/delete/' + id;
			await this.axiosInstance.delete(url, this.apiConfig);
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public calculateImageCoordinates(model: ITablesModel, pageNumber: number, width: number, height: number, generateId: boolean): ICoordinate[] {
		const page: IPage = model.pages.find((pg) => {
			return pg.index === pageNumber - 1;
		});

		const tmpFilteredTables: ITable[] = model.tables.filter((table: ITable) => {
			return table.pageNumber === pageNumber;
		});
		const tmpCoordinates = [];
		for (let index = 0; index < tmpFilteredTables.length; index++) {
			const table: ITable = tmpFilteredTables[index];
			tmpCoordinates.push({
				x: this.pdfService.fromPdfToImage(width, page.width, table.x), // x_pdf * width_image / width_page
				y: this.pdfService.fromPdfToImage(height, page.height, table.y), // (height_pdf - y_pdf) * height_image / height_pdf
				width: this.pdfService.fromPdfToImage(width, page.width, table.width),
				height: this.pdfService.fromPdfToImage(height, page.height, table.height),
				id: generateId === true ? shortid.generate() : table._id,
			});
		}
		return tmpCoordinates;
	}
}

import { IFileModel } from 'models/IFileModel';
import { IImageModel } from 'models/IImageModel';
import { ErrorModel } from 'models/IErrorModel';

export class HelperService {
	public hasImage(file: IFileModel): boolean {
		if (file && file.images && file.images.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	public hasMol(image: IImageModel): boolean {
		if (image && image.mol && image.mol !== '') {
			return true;
		} else {
			return false;
		}
	}

	public hasThumbnails(file: IFileModel): boolean {
		if (file && file.thumbnails && file.thumbnails.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	public findImageById(file: IFileModel, id: string): IImageModel {
		const selector = (image: IImageModel) => {
			return image._id === id;
		};
		if (file.images) {
			const image: IImageModel = file.images.find(selector);
			return image;
		} else {
			return undefined;
		}
	}

	public getImageIndexById(imageList: IImageModel[], id: string): number {
		const selector = (image: IImageModel) => {
			return image._id === id;
		};
		if (imageList) {
			const imageIndex: number = imageList.findIndex(selector);
			return imageIndex;
		} else {
			return -1;
		}
	}

	public downloadSdf(model: IFileModel, items: IImageModel[], fileTitle?: string) {
		// Convert Object to JSON
		let content: string = '';

		for (let index = 0; index < items.length; index++) {
			const element: IImageModel = items[index];
			content += element.mol + '\n';

			content += '>  ';
			content += '<fileName>\n';
			content += model.originalFileName + '\n';

			content += '\n>  ';
			content += '<pageNumber>\n';
			content += element.pageNumber + '\n';

			content += '\n>  ';
			content += '<imageNumber>\n';
			content += element.imageNumber + '\n';

			content += '\n$$$$\n';
		}

		var exportedFileName = fileTitle + '.sdf' || 'export.sdf';

		// var blob = new Blob([content], { type: 'text/plain;charset=utf-8;' });
		// if (navigator.msSaveBlob) {
		// 	// IE 10+
		// 	navigator.msSaveBlob(blob, exportedFileName);
		// } else {
		// 	var link = document.createElement('a');
		// 	if (link.download !== undefined) {
		// 		// feature detection
		// 		// Browsers that support HTML5 download attribute
		// 		var url = URL.createObjectURL(blob);
		// 		link.setAttribute('href', url);
		// 		link.setAttribute('download', exportedFileName);
		// 		link.style.visibility = 'hidden';
		// 		document.body.appendChild(link);
		// 		link.click();
		// 		document.body.removeChild(link);
		// 	}
		// }
	}

	public downloadMol(item: IImageModel, fileTitle?: string) {
		// Convert Object to JSON
		let content: string = item.mol;

		var exportedFileName = fileTitle ? fileTitle + '.mol' : 'export.mol';

		// var blob = new Blob([content], { type: 'text/plain;charset=utf-8;' });
		// if (navigator.msSaveBlob) {
		// 	// IE 10+
		// 	navigator.msSaveBlob(blob, exportedFileName);
		// } else {
		// 	var link = document.createElement('a');
		// 	if (link.download !== undefined) {
		// 		// feature detection
		// 		// Browsers that support HTML5 download attribute
		// 		var url = URL.createObjectURL(blob);
		// 		link.setAttribute('href', url);
		// 		link.setAttribute('download', exportedFileName);
		// 		link.style.visibility = 'hidden';
		// 		document.body.appendChild(link);
		// 		link.click();
		// 		document.body.removeChild(link);
		// 	}
		// }
	}

	public validateEmail(email): boolean {
		const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regexp.test(email);
	}

	public handleError(error, redirect = true) {
		if (error.response) {
			if (error.response.status.toString() === '401') {
				if (redirect) {
					window.location.href = '/signin';
				}
			}
			// The backend is running
			throw new ErrorModel(`${error.response.statusText} - ${error.response.data.message}`, error.response.data.statusCode, error.stack);
			// throw new Error(`${error.response.statusText} - ${error.response.data.message}`);
		} else {
			// The backend is stopped
			throw new ErrorModel(error.message, error.status);
			// throw new Error(error.message);
		}
	}
}

import React, { Suspense, useEffect, useState } from 'react';
import { Box, CircularProgress, Link } from '@material-ui/core';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { NavigationLinks } from 'models/NavigationLinks';

import PrivacyPolicy from 'components/public/PrivacyPolicy';
import CookiePolicy from 'components/public/CookiePolicy';
import TermsOfService from 'components/public/TermsOfService';
import Disclaimer from 'components/public/Disclaimer';
import TextExtraction from './PageTextExtraction';
import LifeSciences from './PageLifeSciences';
import BusinessAutomation from './PageBusinessAutomation';
import { Helmet } from 'react-helmet';
import { PublicPageContactUs } from './PageContactUs';
import { CookieBanner } from '@palmabit/react-cookie-law';
import { PublicPricing } from './PagePublicPricing';
import { NavBar } from './Components/NavBar';

const PublicPageContent = React.lazy(() => import('./PublicPageContent'));
const GoogleRedirect = React.lazy(() => import('./GoogleRedirect'));

export default function PublicPageLayout() {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setIsLoaded(true);
		}, 1000);
	}, []);

	function createScriptBlock(src: string) {
		const script = document.createElement('script');
		script.src = src;
		script.async = true;
		document.body.appendChild(script);
	}

	createScriptBlock('/assets/web/assets/jquery/jquery.min.js');
	createScriptBlock('/assets/popper/popper.min.js');
	createScriptBlock('https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.5');
	createScriptBlock('https://apis.google.com/js/plusone.js');
	createScriptBlock('/assets/facebook-plugin/facebook-script.js');
	createScriptBlock('/assets/bootstrap/js/bootstrap.min.js');
	createScriptBlock('/assets/smoothscroll/smooth-scroll.js');
	createScriptBlock('/assets/tether/tether.min.js');
	createScriptBlock('/assets/dropdown/js/nav-dropdown.js');
	createScriptBlock('/assets/dropdown/js/navbar-dropdown.js');
	createScriptBlock('/assets/touchswipe/jquery.touch-swipe.min.js');
	createScriptBlock('/assets/viewportchecker/jquery.viewportchecker.js');
	createScriptBlock('/assets/as-pie-progress/jquery-as-pie-progress.min.js');
	createScriptBlock('/assets/parallax/jarallax.min.js');
	createScriptBlock('/assets/sociallikes/social-likes.js');
	createScriptBlock('/assets/theme/js/script.js');
	createScriptBlock('/assets/formoid/formoid.min.js');

	return (
		<React.Fragment>
			<Helmet>
				<title>Walheda - Data and insight extraction is (not) magic.</title>
				<meta name="description" content="Walheda - Data and insight extraction" />
				<link rel="stylesheet" href="/assets/web/assets/walheda-icons/walheda-icons.css" />
				<link rel="stylesheet" href="/assets/web/assets/walheda-icons-bold/mobirise-icons-bold.css" />
				<link rel="stylesheet" href="/assets/walheda/css/navbar.css" />
				<link rel="stylesheet" href="/assets/facebook-plugin/style.css" />
				<link rel="stylesheet" href="/assets/bootstrap/css/bootstrap.min.css" />
				<link rel="stylesheet" href="/assets/bootstrap/css/bootstrap-grid.min.css" />
				<link rel="stylesheet" href="/assets/bootstrap/css/bootstrap-reboot.min.css" />
				<link rel="stylesheet" href="/assets/tether/tether.min.css" />
				<link rel="stylesheet" href="/assets/dropdown/css/style.css" />
				<link rel="stylesheet" href="/assets/socicon/css/styles.css" />
				<link rel="stylesheet" href="/assets/animatecss/animate.min.css" />
				<link rel="stylesheet" href="/assets/as-pie-progress/css/progress.min.css" />
				<link rel="stylesheet" href="/assets/theme/css/style.css" />
				<link rel="preload" as="style" href="/assets/walheda/css/mbr-additional.css" />
				<link rel="stylesheet" href="/assets/walheda/css/mbr-additional.css" type="text/css" />
			</Helmet>
			{!isLoaded && (
				<Box display="flex" alignItems="center" justifyContent="center" m={0} p={4}>
					<div>
						<CircularProgress disableShrink />
					</div>
					<div>&nbsp;&nbsp;&nbsp;LOADING...</div>
				</Box>
			)}
			<BrowserRouter>
				<Suspense
					fallback={
						<Box display="flex" alignItems="center" justifyContent="center" m={0} p={4}>
							<CircularProgress disableShrink />
						</Box>
					}
				></Suspense>
				{isLoaded && (
					<div>
						<section id="menu2-0">
							<NavBar />
						</section>
						<div>
							<CookieBanner
								message="We use cookies to give you the best online experience. We use our own cookies, as well as third parties in order to make the navigation of our website easy and safe for our users.
                        In turn, we use cookies to measure and obtain statistical data about the navigation of the users. You can configure and accept the use of the cookies. Necessary cookies must be accepted to use our site."
								wholeDomain={true}
								showPreferencesOption={true}
								showMarketingOption={false}
								preferencesDefaultChecked={true}
								statisticsDefaultChecked={true}
								onAccept={() => {}}
								onAcceptPreferences={() => {}}
								onAcceptStatistics={() => {}}
								onAcceptMarketing={() => {}}
								privacyPolicyLinkText="Cookie Policy"
								policyLink={NavigationLinks.COOKIE_POLICY}
								styles={{
									dialog: { position: 'fixed', bottom: '0px', left: '0px', right: '0px', zIndex: '100000', backgroundColor: 'rgb(248, 247, 247)', padding: '10px' },
									button: { padding: '8px 12px 8px 12px', marginRight: '8px', border: '0px', backgroundColor: '#444444', color: '#eeeeee', cursor: 'pointer' },
								}}
							/>
						</div>
						<Switch>
							<Route path={NavigationLinks.GOOGLE_REDIRECT} exact={true} component={GoogleRedirect} />
							<Route path={NavigationLinks.PRIVACY_POLICY} exact={true} component={PrivacyPolicy} />
							<Route path={NavigationLinks.COOKIE_POLICY} exact={true} component={CookiePolicy} />
							<Route path={NavigationLinks.PAGE_TEXT_EXTRACTION} exact={true} component={TextExtraction} />
							<Route path={NavigationLinks.PAGE_LIFE_SCIENCES} exact={true} component={LifeSciences} />
							<Route path={NavigationLinks.PAGE_BUSINESS_AUTOMATION} exact={true} component={BusinessAutomation} />
							<Route path={NavigationLinks.DISCLAIMER} exact={true} component={Disclaimer} />
							<Route path={NavigationLinks.TERMS_OF_SERVICE} exact={true} component={TermsOfService} />
							<Route path={NavigationLinks.PAGE_PUBLIC_PRICING} exact={true} component={PublicPricing} />
							<Route path={NavigationLinks.PAGE_PUBLIC_CONTACT_US} exact={true} component={PublicPageContactUs} />
							<Route path={NavigationLinks.PUBLIC} component={PublicPageContent} />
						</Switch>
					</div>
				)}
			</BrowserRouter>
		</React.Fragment>
	);
}

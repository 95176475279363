import React, { FC, ReactElement } from 'react';
import { Box, Typography } from '@material-ui/core';

export interface IPageTitleProps {
	title: ReactElement;
	description: string;
}

export const PageTitle: FC<IPageTitleProps> = (props: IPageTitleProps) => {
	const { title, description } = props;
	return (
		<Box mb={3} ml={3} mr={3}>
			<Typography variant="h5" align="left">
				{title}
			</Typography>
			<Typography align="left" variant="body2" gutterBottom>
				{description}
			</Typography>
		</Box>
	);
};

import { React, Grid, Container, useEffect, Services, Box } from '../globals/GlobalIncludes';
import { Footer } from './Footer';

export default function LifeSciences() {
	const services: Services = new Services();

	useEffect(() => {}, []);

	return (
		<React.Fragment>
			<Grid container>
				<Grid item xs={12} style={{ marginTop: '80px' }}>
					<section className="mbr-section content4 cid-s6NVD50wMr" id="content4-19">
						<div style={{ maxWidth: '1200px', margin: 'auto' }}>
							<Grid container>
								<Grid item xs={12} md={6}>
									<div className="media-container-row">
										<div className="title col-12 col-md-8">
											<h2 className="align-center pb-3 mbr-fonts-style display-1">
												<strong>LIFE SCIENCES</strong>
											</h2>
											<h3 className="mbr-section-subtitle align-center mbr-light mbr-fonts-style display-5">Extract chemical molecules from pictures and pdf </h3>
										</div>
									</div>
									<Box mt={1} display="flex" justifyContent="center">
										<div className="mbr-section-btn align-right py-4">
											<a className="btn btn-primary display-4" href="/register">
												GET STARTED
											</a>
											<a className="btn btn-primary display-4" href="https://aws.amazon.com/marketplace/pp/B08CHJ9BMM" target="_blank" rel="noreferrer">
												TRY ON AMAZON
											</a>
										</div>
									</Box>
								</Grid>
								<Grid item xs={12} md={6}>
									<img src="/assets/images/ls/life_science.svg" alt="Text and Table Extraction" title="Text and Table Extraction" width="100%" style={{ maxWidth: '800px' }} />
								</Grid>
							</Grid>
						</div>
					</section>

					<section className="progress-bars3 cid-s6Ot5JsTCE" id="progress-bars3-1e" style={{ paddingBottom: '96px' }}>
						<div className="container">
							<h2 className="mbr-section-title pb-3 align-center mbr-fonts-style display-2">
								<strong>Grab the Chemistry in Pictures</strong>
							</h2>
							<h3 className="mbr-section-subtitle mbr-fonts-style display-5">
								Chemical structures are many times represented in pictures or as images reside in a PDF file. Walheda.com provides a way to extract them into a computer understandable format like .mol or .sdf files.
							</h3>
							<div className="container" style={{ marginTop: '48px' }}>
								<div className="row justify-content-center content-row">
									<img src="/assets/images/ls/molecule_extraction.gif" width="80%" height="auto" alt="Searchable PDF conversion" />
								</div>
							</div>
						</div>
					</section>

					<section className="features1 cid-s6OmBO46mA" id="features1-1b" style={{ backgroundColor: '#f5f5f5' }}>
						<div className="container">
							<div className="media-container-row">
								<div className="card p-3 col-12 col-md-6 col-lg-4">
									<div className="card-img pb-3" style={{ height: '250px' }}>
										<img src="/assets/images/ls/ls_chemical_drawing.svg" alt="Walheda" width="50%" />
									</div>
									<div className="card-box">
										<h4 className="card-title py-3 mbr-fonts-style display-5">Chemical Drawing</h4>
										<p className="mbr-text mbr-fonts-style display-7">Draw chemical structures in the visual editor and download the result as a mol file</p>
									</div>
								</div>
								<div className="card p-3 col-12 col-md-6 col-lg-4">
									<div className="card-img pb-3" style={{ height: '250px' }}>
										<img src="/assets/images/ls/ls_molecule_extraction.svg" alt="Walheda" width="50%" />
									</div>
									<div className="card-box">
										<h4 className="card-title py-3 mbr-fonts-style display-5">Molecule Extraction</h4>
										<p className="mbr-text mbr-fonts-style display-7">Extract the molecule from image or the molecules from PDF files and download as chemical files (sdf, mol)</p>
									</div>
								</div>
								<div className="card p-3 col-12 col-md-6 col-lg-4">
									<div className="card-img pb-3" style={{ height: '250px' }}>
										<img src="/assets/images/ls/ls_error_correction.svg" alt="Walheda" width="50%" />
									</div>
									<div className="card-box">
										<h4 className="card-title py-3 mbr-fonts-style display-5">Error Correction</h4>
										<p className="mbr-text mbr-fonts-style display-7">
											Chemical structure detection on images can not guarantee 100% accuracy. We provide a way to compare the original and the detected molecule. If there is any difference, you can correct it in place
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="mbr-section info1 cid-s6OpO6IhNL" id="info1-1c">
						<div className="container">
							<div className="row justify-content-center content-row">
								<div className="media-container-column title col-12 col-lg-7 col-md-6">
									<h3 className="mbr-section-subtitle align-left mbr-light pb-3 mbr-fonts-style display-5">If you prefer the pay-as-you-go services we recommend to use the molecule extraction service through AWS</h3>
									<h2 className="align-left mbr-bold mbr-fonts-style display-2">TRY ON AMAZON AWS MARKETPLACE</h2>
								</div>
								<div className="media-container-column col-12 col-lg-3 col-md-4">
									<div className="mbr-section-btn align-right py-4">
										<a className="btn btn-primary display-4" href="https://aws.amazon.com/marketplace/pp/B08CHJ9BMM" target="_blank" rel="noreferrer">
											TRY ON AMAZON
										</a>
									</div>
								</div>
							</div>
						</div>
					</section>
				</Grid>
			</Grid>
			<Footer />
		</React.Fragment>
	);
}

import { React, Grid, Container, useEffect, Services, Link, makeStyles, NavigationLinks } from '../globals/GlobalIncludes';
import { SubscribeFooter } from './SubscribeFooter';
import { Link as RouterLink } from 'react-router-dom';
import TwitterIcon from '@material-ui/icons/Twitter';

const useStyles = makeStyles((theme) => ({
	link: {
		color: '#cccccc',
		textDecoration: 'none',
		'&:hover': {
			color: '#ffffff',
			textDecoration: 'none',
		},
	},
}));

export const Footer: React.FC = (props) => {
	const services: Services = new Services();
	const classes = useStyles();

	useEffect(() => {}, []);

	return (
		<React.Fragment>
			<section className="footer4 cid-s6giFPLe2E" id="footer4-8">
				<div className="container">
					<div className="media-container-row content mbr-white">
						<div className="col-md-3 col-sm-4">
							<div className="mb-3 img-logo">
								<a href="https://walheda.com">
									<img src="/assets/images/logo_graphic.png" alt="WALHEDA" title="WALHEDA" />
								</a>
							</div>
						</div>
						<div className="col-md-4 col-sm-8">
							<p className="mb-4 foot-title mbr-fonts-style display-7">LEGAL</p>
							<p className="mbr-text mbr-fonts-style foot-title display-7">
								<Link
									component={RouterLink}
									to={NavigationLinks.TERMS_OF_SERVICE}
									className={classes.link}
									onClick={() => {
										window.scrollTo(0, 0);
									}}
								>
									Terms of Service
								</Link>
							</p>
							<p className="mb-3 mbr-fonts-style foot-title display-7">
								<Link
									component={RouterLink}
									to={NavigationLinks.PRIVACY_POLICY}
									className={classes.link}
									onClick={() => {
										window.scrollTo(0, 0);
									}}
								>
									Privacy Policy
								</Link>
							</p>
							<p className="mb-3 mbr-fonts-style foot-title display-7">
								<Link
									component={RouterLink}
									to={NavigationLinks.COOKIE_POLICY}
									className={classes.link}
									onClick={() => {
										window.scrollTo(0, 0);
									}}
								>
									Cookie Policy
								</Link>
							</p>
							<p className="mb-3 mbr-fonts-style foot-title display-7">
								<Link
									component={RouterLink}
									to={NavigationLinks.DISCLAIMER}
									className={classes.link}
									onClick={() => {
										window.scrollTo(0, 0);
									}}
								>
									Disclaimer
								</Link>
							</p>
						</div>
						<div className="col-md-4 offset-md-1 col-sm-12">
							<p className="mb-4 foot-title mbr-fonts-style display-7">SUBSCRIBE</p>
							<p className="mbr-text mbr-fonts-style form-text display-7">Get frequent updates right in your mailbox.</p>
							<SubscribeFooter />
							<p className="mb-4 mbr-fonts-style foot-title display-7">CONNECT WITH US</p>
							<div className="social-list pl-0 mb-0">
								<div className="soc-item">
									<a href="https://twitter.com/walheda" target="_blank" rel="noopener noreferrer">
										<TwitterIcon />
									</a>
								</div>
								<div className="soc-item">
									<a href="https://m.me/walheda" target="_blank" rel="noopener noreferrer">
										<i className="icofont-facebook-messenger icofont-lg"></i>
									</a>
								</div>
								<div className="soc-item">
									<a href="https://www.facebook.com/walheda" target="_blank" rel="noopener noreferrer">
										<i className="icofont-facebook icofont-lg"></i>
									</a>
								</div>
								<div className="soc-item">
									<a href="https://www.linkedin.com/company/walheda" target="_blank" rel="noopener noreferrer">
										<i className="icofont-linkedin  icofont-lg"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-lower">
						<div className="media-container-row">
							<div className="col-sm-12">
								<hr />
							</div>
						</div>
						<div className="media-container-row mbr-white">
							<div className="col-sm-12 copyright">
								<p className="mbr-text mbr-fonts-style display-7">© Copyright {new Date(Date.now()).getFullYear()} Walheda - All Rights Reserved</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</React.Fragment>
	);
};

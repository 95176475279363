import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { React, useState, Typography, NavigationLinks, Link } from '../../globals/GlobalIncludes';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '../../globals/GlobalIncludes';

export interface INavBarProps {}

export const NavBar: React.FC<INavBarProps> = (props: INavBarProps) => {
	const [click, setClick] = useState(false);
	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => setClick(false);

	useEffect(() => {}, []);

	return (
		<div className="header">
			<div className="logo-nav">
				<div className="logo-container">
					<a href={process.env.REACT_APP_URL}>
						{/* <Logo className="logo" /> */}
						<img src="/assets/images/logo.svg" alt="walheda.com" title="Walheda" style={{ height: '4rem' }} />
					</a>
				</div>
				<Box>
					<ul className={click ? 'nav-options active' : 'nav-options'}>
						<li className="option" onClick={closeMobileMenu} style={{ margin: 'auto' }}>
							<Link
								component={RouterLink}
								to={NavigationLinks.PUBLIC}
								style={{ textDecoration: 'none', fontWeight: 'bold' }}
								className="nav-link link text-primary display-4"
								onClick={() => {
									setClick(false);
									window.scrollTo(0, 0);
								}}
							>
								HOME
							</Link>
						</li>
						<li className="option" onClick={closeMobileMenu} style={{ margin: 'auto' }}>
							<Link
								component={RouterLink}
								to={NavigationLinks.PAGE_BUSINESS_AUTOMATION}
								style={{ textDecoration: 'none', fontWeight: 'bold' }}
								className="nav-link link text-primary display-4"
								onClick={() => {
									setClick(false);
									window.scrollTo(0, 0);
								}}
							>
								BUSINESS AUTOMATION
							</Link>
						</li>
						<li className="option" onClick={closeMobileMenu} style={{ margin: 'auto' }}>
							<Link
								component={RouterLink}
								to={NavigationLinks.PAGE_TEXT_EXTRACTION}
								style={{ textDecoration: 'none', fontWeight: 'bold' }}
								className="nav-link link text-primary display-4"
								onClick={() => {
									setClick(false);
									window.scrollTo(0, 0);
								}}
							>
								TEXT PROCESSING
							</Link>
						</li>
						<li className="option" onClick={closeMobileMenu} style={{ margin: 'auto' }}>
							<Link
								component={RouterLink}
								to={NavigationLinks.PAGE_PUBLIC_PRICING}
								style={{ textDecoration: 'none', fontWeight: 'bold' }}
								className="nav-link link text-primary display-4"
								onClick={() => {
									setClick(false);
									window.scrollTo(0, 0);
								}}
							>
								PRICING
							</Link>
						</li>
						<li className="option" onClick={closeMobileMenu} style={{ margin: 'auto' }}>
							<a className="nav-link link text-primary display-4" href="/register" style={{ fontWeight: 'bold' }}>
								SIGN UP
							</a>
						</li>
						<li className="option" onClick={closeMobileMenu} style={{ margin: 'auto' }}>
							<Link
								component={RouterLink}
								to={NavigationLinks.PAGE_PUBLIC_CONTACT_US}
								style={{ textDecoration: 'none', margin: '0px' }}
								className="btn btn-sm btn-primary display-4"
								onClick={() => {
									window.scrollTo(0, 0);
								}}
							>
								Contact Us
							</Link>
						</li>
					</ul>
				</Box>
			</div>

			<div className="mobile-menu" onClick={handleClick}>
				{click ? <img src="/assets/images/x.svg" alt="walheda.com" title="Walheda" className="menu-icon" /> : <img src="/assets/images/menu.svg" alt="walheda.com" title="Walheda" className="menu-icon" />}
			</div>
		</div>
	);
};

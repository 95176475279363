import { BaseApiService } from './BaseApiService';
import ApiResponse from '../models/ApiResponse';
import { AxiosResponse } from 'axios';
import { IOrganization } from '../models/IOrganizationModel';
import { ApiKeyTypes } from 'models/IApiKeyModel';
import { MemberRoles } from 'models/IOrganizationMembersModel';

export class OrganizationService extends BaseApiService {
	// ******************************************************************************
	// CREATE ORGANIZATION
	// ******************************************************************************
	public async create(org: IOrganization): Promise<IOrganization> {
		try {
			const url = 'v1.0/api/organizations/create';
			const apiResponse: AxiosResponse<IOrganization> = await this.axiosInstance.post(url, org, this.apiConfig);
			return apiResponse.data;
		} catch (error) {
			this.helperService.handleError(error, false);
		}
	}

	// ******************************************************************************
	// GET ORGANIZATION
	// ******************************************************************************
	public async retrieveOne(id: string): Promise<IOrganization> {
		try {
			const url = 'v1.0/api/organizations/' + id;
			const configOptions = this.getAuthHeader(this.accessToken);
			const results: AxiosResponse<IOrganization> = await this.axiosInstance.get(url, configOptions);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async retrieveAll(): Promise<IOrganization[]> {
		try {
			const url = 'v1.0/api/organizations/list';
			const configOptions = this.getAuthHeader(this.accessToken);
			const results: AxiosResponse<IOrganization[]> = await this.axiosInstance.get(url, configOptions);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	// ******************************************************************************
	// UPDATE ORGANIZATION
	// ******************************************************************************
	/**
	 * Updates an organization in DB.
	 *
	 * @param {string} id
	 * @return {*}  {Promise<void>}
	 * @memberof OcrService
	 */
	public async update(id: string, model: IOrganization): Promise<IOrganization> {
		try {
			const url = 'v1.0/api/organizations/update/' + id;
			const apiResponse: AxiosResponse<IOrganization> = await this.axiosInstance.post(url, model, this.apiConfig);
			return apiResponse.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 * Updates an organization in DB.
	 *
	 * @param {string} id
	 * @return {*}  {Promise<void>}
	 * @memberof OcrService
	 */
	public async generateAPIKey(id: string, type: ApiKeyTypes): Promise<IOrganization> {
		try {
			const url = 'v1.0/api/organizations/generate/apikey/' + id;
			const data = {
				type: type,
			};
			const apiResponse: AxiosResponse<IOrganization> = await this.axiosInstance.post(url, data, this.apiConfig);
			return apiResponse.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 * Adds a new member to the organization with the selected role
	 *
	 * @param {string} id
	 * @param {string} newMember
	 * @param {MemberRoles} role
	 * @return {*}  {Promise<IOrganization>}
	 * @memberof OrganizationService
	 */
	public async addNewMember(id: string, newMember: string, role: MemberRoles): Promise<IOrganization> {
		try {
			const url = 'v1.0/api/organizations/add/member/' + id;
			const data = {
				newMember,
				role,
			};
			const apiResponse: AxiosResponse<IOrganization> = await this.axiosInstance.post(url, data, this.apiConfig);
			return apiResponse.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	// ******************************************************************************
	// DELETE ORGANIZATION
	// ******************************************************************************

	/**
	 * Deletes one meber of the organization
	 *
	 * @param {string} orgId
	 * @param {*} memberId
	 * @return {*}  {Promise<IOrganization>}
	 * @memberof OrganizationService
	 */
	public async deleteMember(orgId: string, memberId): Promise<IOrganization> {
		try {
			const url = `v1.0/api/organizations/delete/member/${orgId}/${memberId}`;
			const apiResponse: AxiosResponse<any> = await this.axiosInstance.delete(url, this.apiConfig);
			return apiResponse.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 * Deletes an organization from the DB. Permanently.
	 *
	 * @param {string} id
	 * @return {*}  {Promise<void>}
	 * @memberof OcrService
	 */
	public async delete(id: string): Promise<Boolean> {
		try {
			const url = 'v1.0/api/organizations/delete/' + id;
			const apiResponse: AxiosResponse<any> = await this.axiosInstance.delete(url, this.apiConfig);
			return apiResponse.data.success;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}
}

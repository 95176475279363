import { React, Grid, Container, useEffect, Services, Box } from '../globals/GlobalIncludes';
import { Footer } from './Footer';

export default function TextExtraction() {
	const services: Services = new Services();

	useEffect(() => {}, []);

	return (
		<React.Fragment>
			<Grid container>
				<Grid item xs={12} style={{ marginTop: '80px' }}>
					<section className="mbr-section content4 cid-s6NWVPcgt0" id="content4-1a">
						<div style={{ maxWidth: '1200px', margin: 'auto' }}>
							<Grid container>
								<Grid item xs={12} md={8}>
									<div className="media-container-row">
										<div className="title col-12 col-md-8">
											<h2 className="align-center pb-3 mbr-fonts-style display-1">
												<strong>TEXT PROCESSING</strong>
											</h2>
											<h3 className="mbr-section-subtitle align-center mbr-light mbr-fonts-style display-5">Extract tabular data with its structure</h3>
										</div>
									</div>
									<Box mt={1} display="flex" justifyContent="center">
										<div className="mbr-section-btn align-right py-4">
											<a className="btn btn-primary display-4" href="/register">
												GET STARTED
											</a>
										</div>
									</Box>
								</Grid>
								<Grid item xs={12} md={4}>
									<img src="/assets/images/tp/text_processing.svg" alt="Text and Table Extraction" title="Text and Table Extraction" width="90%" />
								</Grid>
							</Grid>
						</div>
					</section>

					<section className="progress-bars3 cid-s6Ot5JsTCE" id="progress-bars3-1e" style={{backgroundColor:'#ffffff'}}>
						<div className="container">
							<h2 className="mbr-section-title pb-3 align-center mbr-fonts-style display-2">
								<strong>Search what wasn't searchable before</strong>
							</h2>
							<h3 className="mbr-section-subtitle mbr-fonts-style display-5">
								Images and image based PDFs (like some scanned PDFs) are not free-text searchable by default. Walheda.com provides a way to make them searchable while keeping the original layout. Load our output documents into your search
								engine or just upload them to the a cloud drive like Google and enjoy the free text based search possibilities where it wasn't possible before.
							</h3>
							<div className="container" style={{ marginTop: '48px' }}>
								<div className="row justify-content-center content-row">
									<img src="/assets/images/tp/text_extraction.gif" width="80%" height="auto" alt="Searchable PDF conversion" />
								</div>
							</div>
						</div>
					</section>

					<section className="features1 cid-s6OmBO46mA" id="features1-1b" style={{ backgroundColor: '#efefef', paddingTop: '156px', paddingBottom: '156px' }}>
						<div className="container">
							<div className="media-container-row">
								<div className="card p-3 col-12 col-md-6 col-lg-4">
									<div className="card-img pb-3" style={{ height: '200px' }}>
										<img src="/assets/images/ba/ba_table_extraction.svg" alt="Yeee" style={{ height: '150px' }} />
									</div>
									<div className="card-box">
										<h4 className="card-title py-3 mbr-fonts-style display-5">Table Extraction</h4>
										<p className="mbr-text mbr-fonts-style display-7">
											Tables are no problem either even if they reside in PDF or in an image file. And those tables do not need to be well formed tables with all the borders. The AI enginge detects them even if they have no borders at
											all. Copy and paste these tables will not keep the table structure.
										</p>
									</div>
								</div>
								<div className="card p-3 col-12 col-md-6 col-lg-4">
									<div className="card-img pb-3" style={{ height: '200px' }}>
										<img src="/assets/images/tp/tp_searchable_pdf.svg" alt="Yeee" style={{ height: '150px' }} />
									</div>
									<div className="card-box">
										<h4 className="card-title py-3 mbr-fonts-style display-5">Searchable PDF</h4>
										<p className="mbr-text mbr-fonts-style display-7">
											You plan to digitalize your printed documentation? Many times the scanned documents only contain images of the scanned pages but there is hope. Even these docs can be interpreted and their text content can be
											extracted. The PDF will look the same but it will contain the text too and you can easily search within the document.
										</p>
									</div>
								</div>
								<div className="card p-3 col-12 col-md-6 col-lg-4">
									<div className="card-img pb-3" style={{ height: '200px' }}>
										<img src="/assets/images/tp/tp_html.svg" alt="Yeee" style={{ height: '150px' }} />
									</div>
									<div className="card-box">
										<h4 className="card-title py-3 mbr-fonts-style display-5">HTML Generation</h4>
										<p className="mbr-text mbr-fonts-style display-7">
											Images and image based PDF files can be converted to HTML files. If you plan to reuse the content on the web, this might be a right solution. The exported html file will not keep the original structure so you can
											style it according to your needs.
										</p>
									</div>
								</div>
								<div className="card p-3 col-12 col-md-6 col-lg-4">
									<div className="card-img pb-3" style={{ height: '200px' }}>
										<img src="/assets/images/tp/tp_text.svg" alt="Yeee" style={{ height: '150px' }} />
									</div>
									<div className="card-box">
										<h4 className="card-title py-3 mbr-fonts-style display-5">Text Generation</h4>
										<p className="mbr-text mbr-fonts-style display-7">
											Images and image based PDF files can be converted to simple text files. This is beneficial in cases when you only need the document content without the extra html tags and without styling. With this option, you can
											keep the output size minimal which might be handy if you handle a large amount of documents.
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="header1 cid-s6ggRan8Fq" id="header16-2" style={{ paddingTop: '96px' }}>
						<div className="mbr-overlay" style={{ opacity: '0.9', backgroundColor: 'rgb(255, 255, 255)' }}></div>
						<div className="container">
							<div className="row justify-content-md-center">
								<div className="col-md-10 align-center" style={{ borderBottom: '3px solid #cccccc', marginBottom: '48px' }}>
									<p className="mbr-text pb-3 mbr-fonts-style display-5">
										<strong>
											<em>Problem:</em>
										</strong>
										<br />
										The digitalized documents cannot be searched, they have no text in them, just imges.
										<br />
										<br />
										<em>
											<strong>Solution:</strong>&nbsp;
										</em>
										<br />
										Walheda.com offers text extraction form images and image based PDFs. The output can be downloaded as PDF, HTML or a TEXT file.
										<br />
										<br />
									</p>
								</div>
								<div className="col-md-10 align-center">
									<p className="mbr-text pb-3 mbr-fonts-style display-5">
										<strong>
											<em>Problem:</em>
										</strong>
										<br />
										PDF files can be full of tables. Copy and paste these tables will not keep the table structure.&nbsp;
										<br />
										<br />
										<em>
											<strong>Solution:</strong>&nbsp;
										</em>
										<br />
										Walheda.com offers table extraction with export to JSON, csv or xlsx as well as simply copy-pasting them to anywhere. From now on, there is no limit in the extraction of tabular data from invoices, contracts, purchase
										orders, price lists, or even ingredients from tables in a recipe book.
										<br />
										<br />
										<Box mt={1} display="flex" justifyContent="center">
											<div className="mbr-section-btn align-right py-4">
												<a className="btn btn-primary display-4" href="/register">
													GET STARTED
												</a>
											</div>
										</Box>
									</p>
								</div>
							</div>
						</div>
					</section>
				</Grid>
			</Grid>
			<Footer />
		</React.Fragment>
	);
}

import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { CopyRight } from '../footer/CopyRight';
import { Services } from '../../services/Services';
import { useGlobalState } from '../../models/GlobalStates';
import { useHistory } from 'react-router-dom';
import { ErrorModel } from '../../models/IErrorModel';
import { Severity } from '../../models/Severity';
import { SnackBarControl } from 'components/layout/SnackBar';
import { Grid, Link } from '@material-ui/core';
import { NavigationLinks } from 'models/NavigationLinks';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		padding: theme.spacing(2, 0, 2),
	},
}));

export default function Register() {
	const classes = useStyles();
	const history = useHistory();
	const [snackVisible, setSnackVisible] = useGlobalState('snackVisible');
	const [snackMessage, setSnackMessage] = useGlobalState('snackMessage');
	const [snackSeverity, setSnackSeverity] = useGlobalState('snackSeverity');
	const services: Services = new Services();
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [password, setPassword] = useState('');
	const [stage1, setStage1] = useState(true);
	const [stage2, setStage2] = useState(false);
	const [stage3, setStage3] = useState(false);
	const [loggedIn, setLoggedIn] = useGlobalState('loggedIn');

	function validateForm() {
		return validateEmail() && validateName() && password.length > 0;
	}

	function validateEmail() {
		return services.helperService.validateEmail(email);
		console.log(email);
	}

	function validateName() {
		return name.length > 0;
	}

	const handleSubmit = async (event: any) => {
		try {
			event.preventDefault();
			const result = await services.userService.register(name, email, password);
			if (result) {
				history.push('/registersuccess');
			}
		} catch (error) {
			const errorModel = error as ErrorModel;
			setSnackMessage(errorModel.getMessageWithStatus());
			setSnackVisible(true);
			setSnackSeverity(Severity.ERROR);
		}
	};

	const handleClose = () => {
		setSnackVisible(false);
	};

	const setStage = (stageNr: number) => {
		if (stageNr === 1) {
			setStage1(true);
			setStage2(false);
			setStage3(false);
		}
		if (stageNr === 2) {
			setStage1(false);
			setStage2(true);
			setStage3(false);
		}
		if (stageNr === 3) {
			setStage1(false);
			setStage2(false);
			setStage3(true);
		}
	};

	const handleStage1KeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			if (validateName()) setStage(2);
		}
	};
	const handleStage2KeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			if (validateEmail()) setStage(3);
		}
	};

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<a href="/">
					<img src="/assets/images/logo.svg" alt="walheda.com" title="Walheda" style={{ height: '3.8rem' }} />
				</a>
				<Grid container style={{ paddingTop: '50px' }} alignItems="center" direction="column" justify="center">
					<Grid item xs={12}>
						<Typography component="h1" variant="h5">
							REGISTER {stage1 && <span>(1/3)</span>}
							{stage2 && <span>(2/3)</span>}
							{stage3 && <span>(3/3)</span>}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{stage1 && <Typography variant="body2">Type your name</Typography>}
						{stage2 && (
							<Button
								color="primary"
								onClick={() => {
									setStage(1);
								}}
							>
								{name}
							</Button>
						)}
						{stage3 && (
							<Button
								color="primary"
								onClick={() => {
									setStage(2);
								}}
							>
								{email}
							</Button>
						)}
					</Grid>
				</Grid>
				<form className={classes.form} onSubmit={handleSubmit}>
					{stage1 && (
						<React.Fragment>
							<TextField variant="outlined" margin="normal" required fullWidth id="name" label="Name" name="name" autoComplete="name" autoFocus={true} value={name} onChange={(e) => setName(e.target.value)} onKeyDown={handleStage1KeyDown} />
							<Button
								type="button"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								disabled={!validateName()}
								onClick={() => {
									setStage(2);
								}}
							>
								NEXT
							</Button>
						</React.Fragment>
					)}
					{stage2 && (
						<React.Fragment>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label="User email"
								name="email"
								autoFocus={true}
								autoComplete="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								onKeyDown={handleStage2KeyDown}
							/>
							<Button
								type="button"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								disabled={!validateEmail()}
								onClick={() => {
									setStage(3);
								}}
							>
								NEXT
							</Button>
						</React.Fragment>
					)}
					{stage3 && (
						<React.Fragment>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoFocus={true}
								autoComplete="current-password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} disabled={!validateForm()}>
								YES, I JOIN
							</Button>
						</React.Fragment>
					)}
				</form>
			</div>
			<Box mt={4}>
				<CopyRight />
			</Box>
			<Box display="flex" justifyContent="center" mt={4}>
				<Link href={NavigationLinks.SIGN_IN} style={{ textDecoration: 'none' }}>
					SIGN IN
				</Link>
			</Box>

			<SnackBarControl opened={snackVisible} message={snackMessage} severity={snackSeverity} handleClose={handleClose} />
		</Container>
	);
}

import ApiResponse from '../models/ApiResponse';
import { BaseApiService } from './BaseApiService';
import { AxiosResponse } from 'axios';
import { IContactRequestModel } from '../models/IContactRequestModel';

export class CommunicationService extends BaseApiService {
	public async sendContactRequest(data: IContactRequestModel): Promise<boolean> {
		try {
			const url = 'v1.0/api/communication/contact-request';
			const results: AxiosResponse<boolean> = await this.axiosInstance.post(url, data);
			return results.data as boolean;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async sendFeedback(email: string, message: string): Promise<boolean> {
		try {
			const url = 'v1.0/api/communication/feedback';
			const results: AxiosResponse<boolean> = await this.axiosInstance.post(url, { email, message });
			return results.data as boolean;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}
}

import { loadStripe, Stripe } from '@stripe/stripe-js';

export class StripeService {
	private _stripePromise: Promise<Stripe>;
	public get stripePromise(): Promise<Stripe> {
		// if (!this.stripePromise) this._stripePromise = this.createStripePromise();
		return this._stripePromise;
	}

	/**
	 *
	 */
	constructor() {
		this._stripePromise = this.createStripePromise();
	}

	private createStripePromise(): Promise<Stripe> {
		return loadStripe(process.env.REACT_APP_STRIPE_API_PUBLIC_KEY);
	}
}

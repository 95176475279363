import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { theme } from 'theme/theme';
import { React, useState, Typography, Slider } from '../../globals/GlobalIncludes';

const useStyles = makeStyles({
	root: {
		//width: 300,
	},
});

const PrettoSlider = withStyles({
	root: {
		color: theme.palette.primary.main,
		height: 8,
	},
	thumb: {
		height: 24,
		width: 24,
		backgroundColor: '#fff',
		border: '2px solid currentColor',
		marginTop: -8,
		marginLeft: -12,
		'&:focus, &:hover, &$active': {
			boxShadow: 'inherit',
		},
	},
	active: {},
	valueLabel: {
		left: 'calc(-50% + 4px)',
	},
	track: {
		height: 8,
		borderRadius: 4,
	},
	rail: {
		height: 8,
		borderRadius: 4,
	},
})(Slider);

const marks = [
	{
		value: 0,
		scaledValue: 0,
		label: '0',
	},
	{
		value: 25,
		scaledValue: 100,
		label: '100',
	},
	{
		value: 50,
		scaledValue: 200,
		label: '200',
	},
	{
		value: 75,
		scaledValue: 500,
		label: '500',
	},
	{
		value: 100,
		scaledValue: 1000,
		label: '1000',
	},
	{
		value: 125,
		scaledValue: 2000,
		label: '2000',
	},
	{
		value: 150,
		scaledValue: 5000,
		label: '5 000',
	},
	{
		value: 175,
		scaledValue: 10000,
		label: '10 000',
	},
	{
		value: 200,
		scaledValue: 20000,
		label: '20 000',
	},
];

export interface IDiscreteSliderProps {
	defaultValue: number;
	onValueChanged: (newValue: number) => void;
}

export const DiscreteSlider: React.FC<IDiscreteSliderProps> = (props: IDiscreteSliderProps) => {
	const classes = useStyles();
	const { defaultValue, onValueChanged } = props;
	const [sliderValue, setSliderValue] = useState(defaultValue);

	useEffect(() => {
		handleSliderChange(null, defaultValue);
	}, []);

	const scale = (value) => {
		const previousMarkIndex = Math.floor(value / 25);
		const previousMark = marks[previousMarkIndex];
		const nextMark = marks[previousMarkIndex + 1];
		const remainder = value % 25;
		if (remainder === 0) {
			return previousMark.scaledValue;
		}
		const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
		return remainder * increment + previousMark.scaledValue;
	};

	function numFormatter(num) {
		if (num > 999 && num < 1000000) {
			return (num / 1000).toFixed(0) + 'K'; // convert to K for number from > 1000 < 1 million
		} else if (num >= 1000000) {
			return (num / 1000000).toFixed(0) + 'M'; // convert to M for number from > 1 million
		} else if (num < 900) {
			return num; // if value < 1000, nothing to do
		}
	}

	const handleSliderChange = (event, newValue) => {
		setSliderValue(newValue);
		let previousMarkIndex = Math.floor(newValue / 25);
		// The maximum index shall not be increased, because there is no more item
		// The minimum index should not be increased either
		previousMarkIndex = previousMarkIndex < 8 ? ++previousMarkIndex : previousMarkIndex;
		onValueChanged(marks[previousMarkIndex].scaledValue);
	};

	return (
		<div className={classes.root}>
			<Typography id="discrete-slider-restrict" gutterBottom>
				Number of pages to process monthly
			</Typography>
			<PrettoSlider defaultValue={defaultValue} value={sliderValue} min={0} max={200} step={1} valueLabelFormat={numFormatter} marks={marks} scale={scale} onChange={handleSliderChange} valueLabelDisplay="auto" aria-labelledby="non-linear-slider" />
		</div>
	);
};

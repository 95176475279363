import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { CopyRight } from '../footer/CopyRight';
import { Services } from '../../services/Services';
import { useHistory } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { NavigationLinks } from '../../models/NavigationLinks';
import { AppStore, IAppStoreModel } from 'models/AppStore';
import { MessageType } from 'models/IMessagePlaceHolder';
import { MessagePlaceHolder } from '../layout/MessagePlaceHolder';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		padding: theme.spacing(2, 0, 2),
	},
}));

const NewPassword: React.FC = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const services: Services = new Services();
	const [password, setPassword] = useState('');
	const [password2, setPassword2] = useState('');
	let { pathname } = useLocation() as any;

	useEffect(() => {
		//console.log(pathname.substring(pathname.lastIndexOf('/') + 1));
	}, []);

	function validateForm() {
		return !password || !password2;
	}

	const handleSubmit = async (event: any) => {
		try {
			event.preventDefault();
			if (password !== password2) {
				// There is a password problem
				AppStore.update((s: IAppStoreModel) => {
					s.messages = { visible: true, message: "The passwords don't match. Please review!", type: MessageType.ERROR };
				});
			} else {
				// The passwords are OK. Let's change the password
				const result = await services.userService.resetPassword(pathname.substring(pathname.lastIndexOf('/') + 1), password);
				if (result) {
					AppStore.update((s: IAppStoreModel) => {
						s.messages = { visible: true, message: 'The password change succeeded!', type: MessageType.SUCCESS };
					});
				} else {
					AppStore.update((s: IAppStoreModel) => {
						s.messages = { visible: true, message: 'Password change failed!', type: MessageType.ERROR };
					});
				}
			}
		} catch (error) {
			AppStore.update((s: IAppStoreModel) => {
				s.messages = { visible: true, message: error.message, type: MessageType.ERROR };
			});
		}
	};

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<a href="/">
					<img src="/assets/images/logo.svg" alt="walheda.com" title="Walheda" style={{ height: '3.8rem' }} />
				</a>

				<Typography component="h1" variant="h5" style={{ paddingTop: '50px' }}>
					PASSWORD RESET
				</Typography>
				<form className={classes.form} onSubmit={handleSubmit}>
					<TextField variant="outlined" margin="normal" required fullWidth name="password" label="New password" type="password" id="password" autoComplete="current-password" value={password} onChange={(e) => setPassword(e.target.value)} />
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password_again"
						label="New password again"
						type="password"
						id="password_again"
						autoComplete="current-password"
						value={password2}
						onChange={(e) => setPassword2(e.target.value)}
					/>
					<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} disabled={validateForm()}>
						CHANGE MY PASSWORD
					</Button>
				</form>
			</div>

			<Box mt={4}>
				<CopyRight />
			</Box>
			<Box display="flex" justifyContent="center" m={3}>
				<Link href={NavigationLinks.SIGN_IN} style={{ textDecoration: 'none' }}>
					BACK TO LOGIN
				</Link>
			</Box>
			<MessagePlaceHolder />
		</Container>
	);
};

export default NewPassword;

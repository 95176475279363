import { BaseApiService } from './BaseApiService';
import { IFileModel } from 'models/IFileModel';
import { AxiosResponse } from 'axios';
import { IImageModel } from 'models/IImageModel';
import { ErrorModel } from 'models/IErrorModel';
import { IThumbnail } from 'models/IThumbnail';

export class OsrService extends BaseApiService {
	/**
	 * File upload function to the server as multipart/form-data. This call requires server side authentication.
	 * The method calls the server's api/text-mining/upload REST endpoint
	 * @param file The uploadable file as a binary array
	 *
	 */
	public async extractMoleculeFromImage(file: any): Promise<IFileModel> {
		try {
			const fd = new FormData();
			fd.append('file', file[0]);

			const url = 'v1.0/api/life-science/osr/image/extract';
			const results: AxiosResponse<IFileModel> = await this.axiosInstance.post(url, fd, this.apiConfig);
			return results.data as IFileModel;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 * File upload function to the server as multipart/form-data. This call requires server side authentication.
	 * The method calls the server's api/text-mining/upload REST endpoint
	 * @param file The uploadable file as a binary array
	 *
	 */
	public async startMoleculeExtractionFromPdf(file: any): Promise<string> {
		try {
			const fd = new FormData();
			fd.append('file', file[0]);
			fd.append('includeImageInResponse', 'true');

			const url = 'v1.0/api/life-science/osr/pdf/extract/start';
			const results: any = await this.axiosInstance.post(url, fd, this.apiConfig);
			return results.data.jobId;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	/**
	 * Gets the results of an extraction based on the jobId parameter. It will retrieve the current processing status even in that case when the extraction is not yet finished.
	 * @param  {string} jobId
	 * @returns Promise
	 */
	public async getMoleculeExtractionResultsFromPdf(jobId: string): Promise<IFileModel> {
		try {
			const url = 'v1.0/api/life-science/osr/pdf/extract/get/' + jobId;
			const results: AxiosResponse<IFileModel> = await this.axiosInstance.get(url, this.apiConfig);
			return results.data as IFileModel;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async getFile(id: string): Promise<IFileModel> {
		try {
			const url = 'v1.0/api/life-science/osr/files/get/' + id;
			const results: AxiosResponse<IFileModel> = await this.axiosInstance.get(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async getFiles(): Promise<IFileModel[]> {
		try {
			const url = 'v1.0/api/life-science/osr/files';
			const results: AxiosResponse<IFileModel[]> = await this.axiosInstance.get<IFileModel[]>(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async getFilesByType(type: string): Promise<IFileModel[]> {
		try {
			const url = 'v1.0/api/life-science/osr/files/bytype/' + type;
			const results: AxiosResponse<IFileModel[]> = await this.axiosInstance.get<IFileModel[]>(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async getThumbnails(fileId: string): Promise<IThumbnail[]> {
		try {
			const url = 'v1.0/api/life-science/osr/files/thumbnails/list/' + fileId;
			const results: AxiosResponse<IThumbnail[]> = await this.axiosInstance.get<IThumbnail[]>(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async delete(id: string): Promise<void> {
		try {
			const url = 'v1.0/api/life-science/osr/files/delete/' + id;
			await this.axiosInstance.delete(url, this.apiConfig);
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async deleteImage(parentId: string, imageId: string): Promise<void> {
		try {
			const url = 'v1.0/api/life-science/osr/files/deleteImage/' + parentId + '/' + imageId;
			await this.axiosInstance.delete(url, this.apiConfig);
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async updateImage(fileModel: IFileModel, imageModel: IImageModel): Promise<void> {
		try {
			const url = 'v1.0/api/life-science/osr/files/updateImage/' + fileModel._id + '/' + imageModel._id;
			const config = this.apiConfig;
			// PUT/POST requires application/x-www-form-urlencoded
			//config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
			const data = { mol: imageModel.mol };
			// const data = JSON.stringify(imageModel);
			await this.axiosInstance.put(url, data, config);
		} catch (error) {
			this.helperService.handleError(error);
		}
	}
}

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import { Omit } from '@material-ui/types';
import { Box, IconButton } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { NavigationLinks } from 'models/NavigationLinks';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useForceUpdate } from 'hooks/ForceUpdateHook';
import { AppStore, IAppStoreModel } from 'models/AppStore';
import { commonStyleSheet } from '../../theme/common.styles';
import { FeedbackDialog } from './FeedbackDialog';

const drawerWidth = 240;

const styles = (theme: Theme) =>
	createStyles({
		categoryHeader: {
			// borderTop: '1px ​solid #efefef',
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
			// backgroundColor: '#0b0f15',
			//background: 'linear-gradient(180deg, rgba(11,15,21,1) 68%, rgba(24,32,44,1) 100%)',
			// color: 'rgba(255, 255, 255, 0.4)',
			color: '#666666',
			// color: theme.palette.secondary.dark,
			fontSize: 24,
		},
		categoryHeaderClosed: {
			color: '#666666',
		},
		categoryHeaderPrimary: {
			color: theme.palette.common.white,
		},
		item: {
			paddingTop: 0,
			paddingBottom: 0,
			color: '#666666',
			'&:hover,&:focus': {
				backgroundColor: 'rgba(0, 0, 0, 0.08)',
			},
		},
		itemCategory: {
			// backgroundColor: '#232f3e',
			// boxShadow: '0 -1px 0 #404854 inset',
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
		},
		itemCategoryLogo: {
			// backgroundColor: '#232f3e',
			// boxShadow: '0 -1px 0 #404854 inset',
			paddingTop: theme.spacing(0),
			paddingBottom: theme.spacing(4),
		},
		navigationArrowLine: {
			// backgroundColor: '#18202c',
			backgroundColor: '#ffffff',
		},
		firebase: {
			fontSize: 24,
			color: theme.palette.common.white,
		},
		itemActiveItem: {
			color: theme.palette.primary.main,
		},
		itemPrimary: {
			fontSize: 'inherit',
			fontWeight: 'bold',
		},
		itemIcon: {
			minWidth: 'auto',
			marginRight: theme.spacing(2),
			// color: theme.palette.primary.main,
		},
		divider: {
			marginTop: theme.spacing(0),
		},
		linkCursor: {
			cursor: 'pointer',
		},
		drawer: {
			//marginRight: '64px',
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
		drawerOpen: {
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: 'hidden',
			width: theme.spacing(7) + 1,
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9) + 1,
			},
		},
	});

export interface NavigatorProps extends Omit<DrawerProps, 'classes'>, WithStyles<typeof styles> {}

function Navigator(props: NavigatorProps) {
	const { open, onClose, classes, ...other } = props;
	const commonStyles = commonStyleSheet();
	const history = useHistory();
	const forceUpdate = useForceUpdate();
	const [dialogOpen, setDialogOpen] = useState(false);

	const categories = [
		{
			id: 'BUSINESS AUTOMATION',
			children: [
				{ id: 'Data Extraction', icon: <i className={clsx('icofont-search-document', commonStyles.iconTitle24)}></i>, link: NavigationLinks.BUSINESS_AUTOMATION_INVOICES, active: false, last: false },
				{ id: 'API', icon: <i className={clsx('icofont-code-alt', commonStyles.iconTitle24)}></i>, link: NavigationLinks.BUSINESS_AUTOMATION_INVOICES_API, active: false, last: true },
			],
		},
		{
			id: 'TEXT PROCESSING',
			children: [
				{ id: 'Text Recognition (OCR)', icon: <i className={clsx('icofont-optic', commonStyles.iconTitle24)}></i>, link: NavigationLinks.TEXT_PROCESSING_OCR, active: false, last: false },
				{ id: 'Table Extraction', icon: <i className={clsx('icofont-table', commonStyles.iconTitle24)}></i>, link: NavigationLinks.TEXT_PROCESSING_TABLES, active: false, last: false },
				{ id: 'API', icon: <i className={clsx('icofont-code-alt', commonStyles.iconTitle24)}></i>, link: NavigationLinks.TEXT_PROCESSING_API, active: false, last: true },
			],
		},
		// {
		// 	id: 'LIFE SCIENCE',
		// 	children: [
		// 		{ id: 'Molecule Extraction', icon: <i className={clsx('icofont-molecule', commonStyles.iconTitle24)}></i>, link: NavigationLinks.LIFE_SCIENCE_MOLECULE_EXTRACTION, active: false, last: false },
		// 		// { id: 'API', icon: <i className={clsx('icofont-code-alt', commonStyles.iconTitle24)}></i>, link: NavigationLinks.LIFE_SCIENCE_API, active: false, last: true },
		// 	],
		// },
		{
			id: ' ',
			children: [
				{ id: 'DASHBOARD', icon: <i className={clsx('icofont-ui-home', commonStyles.iconTitle24)}></i>, link: NavigationLinks.APP, active: false, last: false },
				{ id: 'PRICING', icon: <i className={clsx('icofont-price', commonStyles.iconTitle24)}></i>, link: NavigationLinks.PRICING, active: false, last: false },
				{ id: 'FEEDBACK', icon: <i className={clsx('icofont-ui-messaging', commonStyles.iconTitle24)}></i>, link: NavigationLinks.FEEDBACK, active: false, last: false },
				// { id: 'API', icon: <i className={clsx('icofont-code-alt', commonStyles.iconTitle24)}></i>, link: NavigationLinks.LIFE_SCIENCE_API, active: false, last: true },
			],
		},
	];
	const [links, setLinks] = useState(categories);

	useEffect(() => {
		// This listen for history change ensures that all the clicked links are tracked and the appropriate link is highlighted as active in the left navigator bar
		history.listen((location, action) => {
			console.log(location.pathname);
			setActiveLink(location.pathname);
			forceUpdate();
		});
	}, []);

	function handleClick(link: string) {
		if (link !== NavigationLinks.FEEDBACK) {
			setActiveLink(link);
			history.push(link);
			forceUpdate();
		} else {
			setDialogOpen(true);
		}
	}

	function setActiveLink(link: string) {
		for (let catItem of categories) {
			for (let child of catItem.children) {
				if (child.link === link) {
					child.active = true;
					// console.log('Current linka: ' + child.link);
				} else {
					child.active = false;
				}
			}
		}
		setLinks(categories);
	}

	const handleDrawerToggleClicked = (open: boolean) => {
		onClose(open, 'backdropClick');
	};

	const onDialogClosed = () => {
		setDialogOpen(false);
	};

	return (
		<React.Fragment>
			<Drawer
				{...other}
				variant="persistent"
				// anchor="left"
				open={true}
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
			>
				<List disablePadding>
					{open && (
						<React.Fragment>
							<ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
								<Box display="flex" justifyContent="center" m={0} p={0} style={{ width: '100%' }}>
									<a href={NavigationLinks.APP}>
										<img src="/assets/images/logo_no_text.svg" alt="walheda.com" title="Walheda" style={{ height: '3.8rem' }} />
									</a>
								</Box>
							</ListItem>
							<ListItem
								className={clsx(classes.item, classes.itemCategory, classes.linkCursor)}
								onClick={() => {
									handleDrawerToggleClicked(false);
								}}
							>
								<ListItemText>
									<Box display="flex" justifyContent="flex-end" m={0} p={0} style={{ width: '100%' }}>
										<ArrowBackIosIcon />
									</Box>
								</ListItemText>
							</ListItem>
						</React.Fragment>
					)}

					{open &&
						links.map(({ id, children }) => (
							<React.Fragment key={id}>
								<ListItem className={classes.categoryHeader}>
									<ListItemText
									// classes={{
									// 	primary: classes.categoryHeaderClosed,
									// }}
									>
										<Box display="flex" justifyContent="flex-start" m={0} p={0} style={{ width: '100%', fontWeight: 'bold' }}>
											{id}
										</Box>
									</ListItemText>
								</ListItem>
								{children.map(({ id: childId, icon, link, active, last }) => (
									<ListItem
										key={childId}
										button
										// className={clsx(classes.item, active === true && classes.itemActiveItem)}
										className={clsx(classes.item, classes.itemCategory, classes.linkCursor, classes.navigationArrowLine, active === true && classes.itemActiveItem)}
										// component={RouterLink}
										// to={link}
										onClick={() => {
											handleClick(link);
										}}
										// style={{ marginBottom: last === true ? '32px' : '0px' }}
										style={{ marginBottom: '0px' }}
									>
										<ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
										<ListItemText
											classes={{
												primary: classes.itemPrimary,
											}}
										>
											{childId}
										</ListItemText>
									</ListItem>
								))}
								{/* <Divider className={classes.divider} /> */}
							</React.Fragment>
						))}
					{!open && (
						<React.Fragment>
							<ListItem className={clsx(classes.firebase, classes.item, classes.itemCategoryLogo)}>
								<Box display="flex" justifyContent="center" m={0} p={0} style={{ width: '100%' }}>
									<a href={NavigationLinks.APP}>
										<img src="/assets/images/logo_no_text.svg" alt="walheda.com" title="Walheda" style={{ width: '50px', height: '3.8rem', paddingBottom: '0.5rem' }} />
									</a>
								</Box>
							</ListItem>
							<ListItem
								className={clsx(classes.item, classes.itemCategory, classes.linkCursor)}
								onClick={() => {
									handleDrawerToggleClicked(true);
								}}
							>
								<Box display="flex" justifyContent="center" m={0} p={0} style={{ width: '100%' }}>
									<ArrowForwardIosIcon />
								</Box>
							</ListItem>
							<ListItem
								className={clsx(classes.item, classes.itemCategory, classes.linkCursor, classes.navigationArrowLine)}
								onClick={() => {
									handleClick(NavigationLinks.APP);
								}}
								style={{ marginBottom: '32px' }}
							>
								<Box display="flex" justifyContent="center" m={0} p={0} style={{ width: '100%' }}>
									<HomeIcon />
								</Box>
							</ListItem>
						</React.Fragment>
					)}
					{!open &&
						links.map(({ id, children }) => (
							<React.Fragment key={id}>
								<ListItem className={classes.categoryHeader}>
									<ListItemText
										classes={{
											primary: classes.categoryHeaderClosed,
										}}
									>
										<Box display="flex" justifyContent="center" m={0} p={0} style={{ width: '100%', fontSize: '0.8em' }}>
											{id === 'TEXT PROCESSING' && 'TP'}
											{id === 'BUSINESS AUTOMATION' && 'BA'}
											{id === 'LIFE SCIENCE' && 'LS'}
										</Box>
									</ListItemText>
								</ListItem>
								{children.map(({ id: childId, icon, link, active, last }) => (
									<ListItem
										key={childId}
										button
										// className={clsx(classes.item, active === true && classes.itemActiveItem)}
										className={clsx(classes.item, classes.itemCategory, classes.linkCursor, classes.navigationArrowLine, active === true && classes.itemActiveItem)}
										// component={RouterLink}
										// to={link}
										onClick={() => {
											handleClick(link);
										}}
										// style={{ marginBottom: last === true ? '32px' : '0px' }}
										style={{ marginBottom: '0px' }}
									>
										<ListItemText>
											<Box display="flex" justifyContent="center" m={0} p={0} style={{ width: '100%' }}>
												{icon}
											</Box>
										</ListItemText>
									</ListItem>
								))}
								{/* <Divider className={classes.divider} /> */}
							</React.Fragment>
						))}
				</List>
			</Drawer>
			<FeedbackDialog open={dialogOpen} onClosed={onDialogClosed} />
		</React.Fragment>
	);
}

export default withStyles(styles)(Navigator);

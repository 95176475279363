import React, { useState, useEffect } from 'react';
import { Services } from '../../services/Services';

export const SubscribeFooter: React.FC = (props) => {
	const services: Services = new Services();
	const [isDisabled, setIsDisabled] = useState(true);
	const [feedbackVisible, setFeedbackVisible] = useState(false);
	const [email, setEmail] = useState('');

	useEffect(() => {
		console.log(`Email: ${email}`);
		if (email) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	}, [email]);

	function onSubmit(e) {
		e.preventDefault();
		console.log(e);
		setFeedbackVisible(true);
		services.communicationService.sendContactRequest({
			name: 'General - Subscriber',
			email,
			message: 'Footer',
		});
	}

	return (
		<div className="media-container-column">
			<form onSubmit={onSubmit} method="POST" className="mbr-form form-with-styler" data-form-title="Walheda Form">
				<input type="hidden" name="email" data-form-email="true" defaultValue="U6Z47TUFiUP0BQZiMabKFjOwWFwaVFDUnhncBCj7WotpfoEx9tHRV8knIziQucET7X78l7LvP+6x7pHnP+BdKAsLFyMZUV7NDdYuHhLKix6Ma8oOYk3yw2bVq5oFipfg" />
				<div className="row form-inline justify-content-center">
					<div hidden={!feedbackVisible} data-form-alert className="alert alert-success col-12">
						Thanks for helping us improve!
					</div>
				</div>
				<div className="dragArea row form-inline justify-content-center">
					<div className="col-auto  form-group " data-for="email">
						<input
							// type="email"
							name="email"
							pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
							placeholder="Email"
							data-form-field="Email"
							required={true}
							className="form-control input-sm input-inverse my-2 display-7"
							id="email-footer4-8"
							value={email}
							onChange={(event) => {
								setEmail(event.target.value);
							}}
						/>
					</div>
					<div className="col-auto  input-group-btn  m-2">
						<button type="submit" className="btn btn-primary m-0 display-4" disabled={isDisabled}>
							Subscribe
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

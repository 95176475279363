import { useEffect, useState } from 'react';
import { useGlobalState } from '../models/GlobalStates';
import { SubscriptionProductTypes } from '../models/ISubscriptionType';

//create your forceUpdate hook
export function useSubscriptionCheck(productType: SubscriptionProductTypes) {
	const [subscriptionsSummary, setSubscriptionsSummary] = useGlobalState('subscriptionsSummary');
	const [hasAccess, setHasAccess] = useState(false);

	useEffect(() => {
		if (subscriptionsSummary && subscriptionsSummary.stripeSubscriptions) {
			let tmpHasAccess = false;
			// Filter the available products based on the type of the subscription we need here
			const availableProducts = subscriptionsSummary.stripeProductPrices.stripeProducts.filter((iProd) => {
				return iProd.type === productType;
			});

			// Iterate through the user's subscriptions
			for (let index = 0; index < subscriptionsSummary.stripeSubscriptions.length; index++) {
				const userSub = subscriptionsSummary.stripeSubscriptions[index];
				let stopIteration = false;

				// Now check if the available subs contain the product the user has subscriptions for
				for (let availableIndex = 0; availableIndex < availableProducts.length; availableIndex++) {
					const availableProduct = availableProducts[availableIndex];
					if (availableProduct.id === userSub.localSubscription.type.stripeProductId) {
						tmpHasAccess = true;
						stopIteration = true;
                        break;
					}
				}
				if (stopIteration) break;
			}

			if (tmpHasAccess) setHasAccess(true);
			else setHasAccess(false);
		} else {
			setHasAccess(false);
		}
		return () => {
			setHasAccess(false);
		};
	}, [subscriptionsSummary]);

	return hasAccess; // update the state to force render
}

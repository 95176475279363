import { makeStyles } from '@material-ui/core';

export const commonStyleSheet = makeStyles((theme) => ({
	notificationBoxNote: {
		border: 'none',
		borderLeft: '5px solid #EEA436',
		backgroundColor: 'rgb(255, 244, 229)',
		width: '100%',
	},
	notificationBoxWarning: {
		border: 'none',
		borderLeft: '5px solid #f44336',
		backgroundColor: 'rgb(253, 236, 234)',
		width: '100%',
	},
	spacingRight1: {
		marginRight: theme.spacing(1),
	},
	spacingLeft1: {
		marginLeft: theme.spacing(1),
	},
	colorPrimaryMain: {
		color: theme.palette.primary.main,
	},
	colorSecondaryMain: {
		color: theme.palette.secondary.main,
	},
	colorSecondaryDark: {
		color: theme.palette.secondary.dark,
	},
	iconTitle24: {
		fontSize: 24,
	},
	iconTitle48: {
		fontSize: 48,
	},
	iconTitle32Op: {
		fontSize: 32,
		opacity: 0.3,
	},
}));

import { useEffect, useState } from 'react';
import { useGlobalState } from '../models/GlobalStates';
import { SubscriptionProductTypes } from '../models/ISubscriptionType';
import { Services } from '../services/Services';

//create your forceUpdate hook
export function useSubscriptionsSummary() {
	const [subscriptionsSummary, setSubscriptionsSummary] = useGlobalState('subscriptionsSummary');
	const [loggedInUser] = useGlobalState('loggedInUser');
	const [subscriptionSummaryReloadNeeded, setSubscriptionSummaryReloadNeeded] = useGlobalState('subscriptionSummaryReloadNeeded');

	useEffect(() => {
		async function reload() {
			if (loggedInUser) {
				const services = new Services();
				const tmpsSubsSumm = await services.subscriptionsService.getSubscriptionsSummary(loggedInUser.organizationId.toString());
				setSubscriptionsSummary(tmpsSubsSumm);
			}
		}
		if (subscriptionSummaryReloadNeeded) {
			reload();
			setSubscriptionSummaryReloadNeeded(false);
		}
	}, [subscriptionSummaryReloadNeeded, loggedInUser]);

	return subscriptionsSummary; // update the state to force render
}

import { AxiosResponse } from 'axios';
import { SubscriptionProductTypes } from 'models/ISubscriptionType';
import { StripeConfig } from '../models/stripe/StripeConfig';
import { ISubscriptions } from '../models/ISubscriptions';
import { BaseApiService } from './BaseApiService';
import { ISubscriptionSummaryDto } from '../models/subscription-summary/subscription.summary.dto';
import { IOrganization } from 'models/IOrganizationModel';
import { IApiCall } from 'models/IApiCall';
import { DateTime } from 'components/globals/GlobalIncludes';
import { ISubscriptionDto } from 'models/subscription-summary/subscription.dto';

export class SubscriptionsService extends BaseApiService {
	// ******************************************************************************
	// CREATE
	// ******************************************************************************
	public async create(org: IOrganization, paymentMethodId: string, productType: string, priceId: string): Promise<ISubscriptions> {
		try {
			// const url = "v1.0/api/auth/register?name=" + name + "&username=" + email + "&password=" + password;
			const url = 'v1.0/api/subscriptions/create';
			const config = this.getAuthHeader(this.accessToken);
			const data = {
				organization: org,
				paymentMethodId: paymentMethodId,
				productType: productType,
				priceId: priceId,
			};
			const apiResponse: AxiosResponse<ISubscriptions> = await this.axiosInstance.post(url, data, config);
			return apiResponse.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	// public async create(orgId: string, productType: string): Promise<ISubscriptions> {
	// 	try {
	// 		// const url = "v1.0/api/auth/register?name=" + name + "&username=" + email + "&password=" + password;
	// 		const url = 'v1.0/api/subscriptions/create';
	// 		const config = this.getAuthHeader(this.accessToken);
	// 		const data = {
	// 			orgId,
	// 			productType,
	// 		};
	// 		const apiResponse: AxiosResponse<ISubscriptions> = await this.axiosInstance.post(url, data, config);
	// 		return apiResponse.data;
	// 	} catch (error) {
	// 		// this.helperService.handleError(error);
	// 	}
	// }

	// ******************************************************************************
	// READ
	// ******************************************************************************
	public async retrieveAll(orgId: string): Promise<ISubscriptions> {
		try {
			const url = 'v1.0/api/subscriptions/list/' + orgId;
			const configOptions = this.getAuthHeader(this.accessToken);
			const results: AxiosResponse<ISubscriptions> = await this.axiosInstance.get(url, configOptions);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async getStripeConfig(): Promise<StripeConfig> {
		try {
			const url = 'v1.0/api/subscriptions/stripe/config';
			const results: AxiosResponse<StripeConfig> = await this.axiosInstance.get<StripeConfig>(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async getStripeSubscription(subId: string): Promise<any> {
		try {
			const url = 'v1.0/api/subscriptions/stripe/' + subId;
			const results: AxiosResponse<any> = await this.axiosInstance.get<any>(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async getSubscriptionsSummary(orgId: string): Promise<ISubscriptionSummaryDto> {
		try {
			const url = 'v1.0/api/subscriptions/summary/' + orgId;
			const results: AxiosResponse<any> = await this.axiosInstance.get<ISubscriptionSummaryDto>(url, this.apiConfig);
			return results.data;
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	// ******************************************************************************
	// UPDATE
	// ******************************************************************************
	public async updatePaymentMethod(customerId: string, newPaymentMethodId: string): Promise<void> {
		try {
			const url = 'v1.0/api/subscriptions/paymentmethod/update';
			const data = {
				customerId: customerId,
				paymentMethodId: newPaymentMethodId,
			};
			await this.axiosInstance.post(url, data, this.apiConfig);
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	public async updateOrganization(organizationId: string, organization: IOrganization): Promise<void> {
		try {
			const url = 'v1.0/api/subscriptions/organization/update';
			const data = {
				organizationId: organizationId,
				organization: organization,
			};
			await this.axiosInstance.post(url, data, this.apiConfig);
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	// ******************************************************************************
	// DELETE
	// ******************************************************************************
	public async cancel(subsId: string, stripeSubId: string): Promise<void> {
		try {
			const url = 'v1.0/api/subscriptions/cancel/' + subsId + '/' + stripeSubId;
			await this.axiosInstance.delete(url, this.apiConfig);
		} catch (error) {
			this.helperService.handleError(error);
		}
	}

	// ******************************************************************************
	// UTILS
	// ******************************************************************************
	public getCallsInDateRange(calls: IApiCall[], startDate: Date, endDate: Date): IApiCall[] {
		const sD = startDate.toISOString === undefined ? startDate.toString() : startDate.toISOString();
		const eD = endDate.toISOString === undefined ? endDate.toString() : endDate.toISOString();
		if (!calls) calls = [];
		let currCalls = calls.filter((call) => {
			return DateTime.fromISO(call.callDate.toString()) > DateTime.fromISO(sD) && DateTime.fromISO(call.callDate.toString()) < DateTime.fromISO(eD);
		});
		if (!currCalls) currCalls = [];
		currCalls = currCalls.sort((a, b) => {
			return DateTime.fromJSDate(a.callDate).toMillis() - DateTime.fromJSDate(b.callDate).toMillis();
		});
		return currCalls;
	}

	public getCallsInPeriod(subscription: ISubscriptionDto, currentPeriodStartDate: Date, howManyBack: number): IApiCall[] {
		const currPeriodStart = currentPeriodStartDate.toISOString === undefined ? DateTime.fromISO(currentPeriodStartDate.toString()) : DateTime.fromJSDate(currentPeriodStartDate);
		let sD: DateTime;
		let eD: DateTime;

		const daysInPeriod: number = subscription.price.recurring.interval === 'month' ? 30 : 365;

		sD = currPeriodStart.minus({ days: howManyBack * daysInPeriod });
		eD = currPeriodStart.minus({ days: (howManyBack - 1) * daysInPeriod });

		if (howManyBack === 0) {
			sD = currPeriodStart;
			eD = DateTime.fromJSDate(new Date(Date.now()));
		}
		return this.getCallsInDateRange(subscription.localSubscription.calls, sD.toJSDate(), eD.toJSDate());
	}

	public getProcessedPagesCountInCallSet(calls: IApiCall[]): number {
		let currPageCalls = 0;
		for (let index = 0; index < calls.length; index++) {
			const call = calls[index];
			currPageCalls += call.pageCount;
		}
		return currPageCalls;
	}
}

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useGlobalState } from 'models/GlobalStates';
import { Services } from 'services/Services';
import { IUserModel } from 'models/IUserModel';
import { AppStore, IAppStoreModel } from 'models/AppStore';

export const PrivateRoute = (props) => {
	const { component: Component, path, ...rest } = props;
	const [, setLoggedIn] = useGlobalState('loggedIn');
	const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
	const [, setSubscriptionsSummary] = useGlobalState('subscriptionsSummary');
	let renderComponent: any;
	const services: Services = new Services();
	const history = useHistory();

	// const accessToken = services.cookieService.getCookie('access_token', {
	// 	path: '/',
	// });
	const accessToken = services.sessionStorage.get('access_token').toString();

	if (!accessToken) {
		renderComponent = (
			<Redirect
				to={{
					pathname: '/signin',
					state: { from: props.location },
				}}
			/>
		);
	} else {
		getMe(accessToken);
		renderComponent = <Component {...props} />;
	}

	async function getMe(pAccessToken: string) {
		try {
			let myself: IUserModel = loggedInUser;
			if (!myself.email) {
				// This happens when the browser page is hard refreshed (the global state set after login is gone in this case)
				myself = await services.userService.getMe(pAccessToken);
				AppStore.update((s: IAppStoreModel) => {
					s.user = myself;
				});
				setLoggedInUser(myself);
				setLoggedIn(true);

				const subscriptionsSummary = await services.subscriptionsService.getSubscriptionsSummary(myself.organizationId.toString());
				setSubscriptionsSummary(subscriptionsSummary);
			}
			if (!myself.email) {
				setLoggedIn(false);
				history.push('/signin');
			} else {
				services.sessionStorage.set('access_token', pAccessToken);
				// services.cookieService.setCookie('access_token', pAccessToken, {
				// 	path: '/',
				// });
				// await AppStore.update((s: IAppStoreModel) => {
				// 	s.user = myself;
				// });
			}
			return;
		} catch (error) {
			history.push('/signin');
		}
	}

	return <Route {...rest} render={(routeProps) => renderComponent} />;
};

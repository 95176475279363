import { Store } from 'pullstate';
import { IMessagePlaceHolder, MessageType } from './IMessagePlaceHolder';
import { IUserModel } from './IUserModel';
import { UserRoles } from './UserRoles';
import { UserStatus } from './UserStatus';
import { ITablesModel } from './tables/ITablesModel';
import { IBADocumentModel } from './1-business-automation/IBADocumentModel';
import { SubscriptionProductTypes } from './ISubscriptionType';
import { StripeProductPriceOption } from './stripe/StripeProductPriceOption';

export interface IAppStoreModel {
	user: IUserModel;
	messages: IMessagePlaceHolder;
	tablesServiceSelection: ITablesModel;
	selectedInvoice: IBADocumentModel;
	tablesServiceLoadedModels: Object;
	tablesServiceLoadedModels2: Array<ITablesModel>;
	stripeProductPriceOption: StripeProductPriceOption;
	activeMenu: string;
}

const storeInitValue: IAppStoreModel = {
	messages: {
		message: 'Init message',
		visible: false,
		type: MessageType.INFO,
	},
	user: {
		_id: '',
		name: 'Béla',
		email: '',
		// password: string;
		status: UserStatus.INACTIVE,
		// confirmationCode: string;
		phone: '',
		description: '',
		age: 0,
		createdAt: new Date(Date.now()),
		modifiedAt: new Date(Date.now()),
		lastLogin: new Date(Date.now()),
		role: UserRoles.USER,
		organizationId: null,
		gender: 0,
	},
	tablesServiceSelection: {
		_id: '',
		createDate: new Date(Date.now()),
		error: null,
		extension: null,
		fileName: null,
		folder: null,
		jobId: null,
		lastModificationDate: new Date(Date.now()),
		mimeType: null,
		originalFileName: null,
		processingTime: null,
		size: null,
		status: null,
		tables: null,
		pages: null,
		thumbnails: null,
		totalPageCount: null,
		totalTablesFound: null,
		userId: null,
	},
	selectedInvoice: {
		_id: '',
		countOfImagesFound: 0,
		countOfImagesProcessed: 0,
		createDate: new Date(Date.now()),
		error: null,
		extension: null,
		images: null,
		jobId: null,
		fields: null,
		lastModificationDate: new Date(Date.now()),
		lineItems: null,
		mimeType: null,
		originalFileName: null,
		pages: null,
		processingTime: null,
		size: 0,
		status: null,
		thumbnails: null,
		totalPageCount: 0,
		tables: null,
		totalTablesFound: 0,
		customFieldTypes: null,
	},
	tablesServiceLoadedModels: {},
	tablesServiceLoadedModels2: new Array<ITablesModel>(),
	stripeProductPriceOption: null,
	activeMenu: '/app',
};

export const AppStore = new Store(storeInitValue);

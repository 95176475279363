import React, { ReactElement, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CustomDialog } from '../../components/globals/CustomDialog';
import { blue } from '@material-ui/core/colors';
import { DialogContent, DialogContentText, DialogActions, Button, TextField, Grid, Typography } from '@material-ui/core';
import { AppStore, IAppStoreModel } from '../../models/AppStore';
import { MessageType } from '../../models/IMessagePlaceHolder';
import { useEffect } from 'components/globals/GlobalIncludes';
import { servicesVersion } from 'typescript';
import { Services } from 'services/Services';

const useStyles = makeStyles((theme) => ({
	avatar: {
		backgroundColor: blue[100],
		color: blue[600],
	},
	warningMsg: {
		color: theme.palette.error.dark,
	},
}));

export interface IFeedbackDialogProps {
	open: boolean;
	onClosed: () => void;
}

// ************************************************************************************************************************************************************
// COMPONENT
// ************************************************************************************************************************************************************
export const FeedbackDialog: React.FC<IFeedbackDialogProps> = (props: IFeedbackDialogProps) => {
	const classes = useStyles();
	const services: Services = new Services();
	const { open, onClosed } = props;

	const [dialogOpen, setDialogOpen] = useState(open);
	const [feedbackLength, setFeedbackLength] = useState(0);
	const [email, setEmail] = useState('');
	const [feedback, setFeedback] = useState('');
	const [shortMessageWarning, setShortMessageWarning] = useState('');

	// ************************************************************************************************************************************************************
	// EFFECTS
	// ************************************************************************************************************************************************************

	useEffect(() => {
		if (open) setDialogOpen(open);
	}, [open]);

	// ************************************************************************************************************************************************************
	// Error handling
	// ************************************************************************************************************************************************************
	function onError(err) {
		AppStore.update((s: IAppStoreModel) => {
			s.messages = { visible: true, message: err.message, type: MessageType.ERROR };
		});
	}

	// ************************************************************************************************************************************************************
	// DIALOG CONTENT
	// ************************************************************************************************************************************************************
	const actions = (
		<React.Fragment>
			<Button onClick={() => onDialogClose(false)} color="primary">
				Close
			</Button>
			<Button onClick={() => onDialogClose(true)} color="primary" variant="contained" autoFocus>
				Send
			</Button>
		</React.Fragment>
	);

	const content = (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="body2" component="div">
					If you have any comments, thoughts, feedback or complaints to share you are at the best place. Feel free to talk to us about anything what's on your mind. Share your email address too (optional) so we can communicate. Otherwise we don't
					connect the logged in user to any feedback so it's anonymous.
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<TextField id="outlined-basic" label="My email (optional)" variant="outlined" fullWidth={true} onChange={(e) => setEmail(e.target.value)} inputProps={{ maxLength: 100 }} helperText="Type your email here in case you need an answer" />
			</Grid>
			<Grid item xs={12}>
				<TextField id="outlined-basic" label="My feedback" variant="outlined" fullWidth={true} multiline rows={8} onChange={(e) => setFeedbackText(e.target.value)} inputProps={{ maxLength: 2000 }} helperText={feedbackLength + ' / 2000'} />
			</Grid>
			{shortMessageWarning.length > 0 && (
				<Grid item xs={12}>
					<Typography variant="body2" component="div" className={classes.warningMsg}>
						{shortMessageWarning}
					</Typography>
				</Grid>
			)}
		</Grid>
	);

	// ************************************************************************************************************************************************************
	// EVENT HANDLERS
	// ************************************************************************************************************************************************************
	async function onDialogClose(value: boolean) {
		try {
			if (value) {
				// TODO: Send the email for us
				if (feedback.length < 10) {
					setShortMessageWarning('Hmmmm... This message seems to be very short. Please write at least a sentence!');
				} else {
					await services.communicationService.sendFeedback(email, feedback);
					AppStore.update((s: IAppStoreModel) => {
						s.messages = { visible: true, message: 'Thank you for your feedback!', type: MessageType.SECONDARY };
					});
					setShortMessageWarning('');
					setDialogOpen(false);
					onClosed();
				}
			} else {
				setShortMessageWarning('');
				setDialogOpen(false);
				onClosed();
			}
		} catch (error) {
			setDialogOpen(false);
			onError(error);
		}
	}
	async function setFeedbackText(value) {
		if (value.length <= 2000) {
			setFeedback(value);
			setFeedbackLength(value.length);
			if (value.length > 10) {
				setShortMessageWarning('');
			}
		}
	}

	// ************************************************************************************************************************************************************
	// RENDERING
	// ************************************************************************************************************************************************************
	return <CustomDialog open={dialogOpen} onClose={null} title={'Send us a message'} content={content} dialogActions={actions} />;
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import { DialogContent, DialogContentText, DialogActions } from "@material-ui/core";

const useStyles = makeStyles({
	avatar: {
		backgroundColor: blue[100],
		color: blue[600],
	},
});

export interface IConfirmDialogProps {
	open: boolean;
	onClose: (value: boolean) => void;
	content: string;
}

export const ConfirmDialog: React.FC<IConfirmDialogProps> = (props: IConfirmDialogProps) => {
	const classes = useStyles();
	const { onClose, open } = props;

	return (
		<Dialog onClose={() => onClose(false)} aria-labelledby="simple-dialog-title" open={open}>
			<DialogTitle id="simple-dialog-title">Confirmation</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{props.content}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose(true)} color="primary">
					Yes
				</Button>
				<Button onClick={() => onClose(false)} color="primary" autoFocus variant='contained'>
					No
				</Button>
			</DialogActions>
		</Dialog>
	);
};

import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import './icofont.min.css';
// import HomePage from "components/HomePage";
import Home from 'components/layout/Home';
import PublicPageLayout from 'components/public/PublicPageLayout';
import { Box, CircularProgress, ThemeProvider } from '@material-ui/core';
import SignIn from 'components/authentication/SignIn';
import Register from 'components/authentication/Register';
import PasswordReset from 'components/authentication/PasswordReset';
import NewPassword from 'components/authentication/NewPassword';
import RegisterSuccess from 'components/authentication/RegistrationSuccess';
import { PrivateRoute } from 'components/layout/PrivateRoute';
import ReactGA from 'react-ga';
import { theme } from 'theme/theme';
import { NavigationLinks } from 'models/NavigationLinks';

// import { AppStore, IAppStoreModel } from 'models/AppStore';

function App() {
	// const appState = AppStore.useState((s: IAppStoreModel) => s);

	useEffect(() => {
		ReactGA.initialize('UA-175193800-1');
		console.log('Analytics initialized');
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<Suspense
					fallback={
						<Box display="flex" alignItems="center" justifyContent="center" m={0} p={4}>
							<CircularProgress disableShrink />
						</Box>
					}
				>
					<Switch>
						<Route path={NavigationLinks.SIGN_IN} component={SignIn} />
						<Route path={NavigationLinks.REGISTER} component={Register} />
						<Route path={NavigationLinks.PASSWORD_RESET} exact={true} component={PasswordReset} />
						<Route path={NavigationLinks.PASSWORD_RESET + NavigationLinks.PARAM_ID} exact={true} component={NewPassword} />
						<Route path={NavigationLinks.REGISTER_SUCCESS} component={RegisterSuccess} />
						<PrivateRoute path={NavigationLinks.APP} component={Home} />
						<Route path={NavigationLinks.PUBLIC} component={PublicPageLayout} />
					</Switch>
				</Suspense>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;

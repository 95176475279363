export class LocalStorageService<T> {
	public set(key: string, value: T) {
		if (value) {
			localStorage[key] = JSON.stringify(value);
			return JSON.parse(localStorage[key]);
		} else {
			return undefined;
		}
	}

	public get(key: string, defaultValue: T = undefined, silent: boolean = true): T {
		var value: string = localStorage[key] || defaultValue;
		if (!silent && !value) throw new Error(key + ' not found in localStorage');
		if (value !== undefined) {
			return JSON.parse(value);
		} else {
			return defaultValue;
		}
	}

	// public setObject(key: string, value: T) {
	// 	localStorage[key] = value;
	// 	return localStorage[key];
	// }

	// public getObject(key: string, defaultValue: T = undefined, silent: boolean = true): T {
	// 	const value: T = this.get(key, defaultValue, silent);
	// 	try {
	// 		return value;
	// 	} catch (e) {
	// 		// will raise error for parsing
	// 		if (!silent) throw new Error('Error in parsing value');
	// 	}
	// }

	public clear() {
		return localStorage.clear();
	}

	public remove(key) {
		return localStorage.removeItem(key);
	}
}

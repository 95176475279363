import { SubscriptionProductTypes } from 'models/ISubscriptionType';
import { React, Paper, Box, makeStyles, Typography, Button, commonStyleSheet, RouterLink, NavigationLinks, useGlobalState, useState, useEffect } from '../globals/GlobalIncludes';

const useStyles = makeStyles({
	fullWidth: {
		width: '100%',
	},
	lockIcon: {
		fontSize: 240,
		opacity: 0.2,
	},
});

export interface IDemoModeNotification {
	// title: string;
	// description: string;
	subscriptionType: SubscriptionProductTypes;
}

export const DemoModeNotification: React.FC<IDemoModeNotification> = (props: IDemoModeNotification) => {
	const classes = useStyles();
	const commonStyles = commonStyleSheet();
	const { subscriptionType } = props;
	const [subscriptionsSummary, setSubscriptionsSummary] = useGlobalState('subscriptionsSummary');
	const [remainingPageCount, setRremainingPageCount] = useState(5);

	useEffect(() => {
		if (subscriptionsSummary && subscriptionType) {
			if (subscriptionType === SubscriptionProductTypes.BUSINESS_AUTOMATION) setRremainingPageCount(subscriptionsSummary.localOrganization.remainingBADemoPages);
			if (subscriptionType === SubscriptionProductTypes.TEXT_PROCESSING) setRremainingPageCount(subscriptionsSummary.localOrganization.remainingTPDemoPages);
			if (subscriptionType === SubscriptionProductTypes.LIFE_SCIENCE) setRremainingPageCount(subscriptionsSummary.localOrganization.remainingLSDemoPages);
		}
	}, [subscriptionsSummary, subscriptionType]);

	// const { title, description } = props;
	return (
		<React.Fragment>
			<Box p={3} pb={6} pt={0} display="flex" justifyContent="center" alignItems="center">
				<Paper variant="outlined" square className={commonStyles.notificationBoxWarning}>
					<Box p={3}>
						<Typography style={{ fontWeight: 'bold' }}>DEMO MODE</Typography>
						You have no subscription yet, so the app is running in demo mode. All the functionality is available but the allowed amount of data to process is limited. In demo mode you can take the following actions:
						<Box display="flex">
							<Box flexGrow={1}>
								{remainingPageCount > 0 && (
									<ul>
										<li>Process maximum of {remainingPageCount} pages all together.</li>
										<li>That could be one PDF with {remainingPageCount} pages</li>
										<li>{remainingPageCount} PDF files with 1 page each</li>
										<li>{remainingPageCount} images (gif, png, jpg)</li>
									</ul>
								)}
							</Box>
							<Box mt={2} p={2} style={{ width: '200px', backgroundColor: '#ffffff', borderRadius: '20px' }}>
								<Box style={{ textAlign: 'center' }}>REMAINING PAGES</Box>
								<Box style={{ textAlign: 'center', fontSize: '3em' }}>{subscriptionsSummary && remainingPageCount}</Box>
							</Box>
						</Box>
						<Box pt={2}>
							<Button variant="contained" color="primary" disableElevation component={RouterLink} to={NavigationLinks.PRICING}>
								Show me the plans!
							</Button>
						</Box>
					</Box>
				</Paper>
			</Box>
		</React.Fragment>
	);
};

import React from 'react';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';

export const CopyRight: React.FC = (props) => {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '} Walheda
			{/* <Link color="inherit" href="https://walheda.com/">
				walheda.com
			</Link>{" "} */} {new Date().getFullYear()}
			{'.'}
		</Typography>
	);
};

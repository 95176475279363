export const businessAutomationPrices = [
	// Monthly prices
	{
		amount: 100,
		price: 20,
		period: 'month',
	},
	{
		amount: 200,
		price: 30,
		period: 'month',
	},
	{
		amount: 500,
		price: 60,
		period: 'month',
	},
	{
		amount: 1000,
		price: 110,
		period: 'month',
	},
	{
		amount: 2000,
		price: 200,
		period: 'month',
	},
	{
		amount: 5000,
		price: 400,
		period: 'month',
	},
	{
		amount: 10000,
		price: 980,
		period: 'month',
	},
	{
		amount: 20000,
		price: 1800,
		period: 'month',
	},
	// Yearly prices
	{
		amount: 100,
		price: 200,
		period: 'year',
	},
	{
		amount: 200,
		price: 300,
		period: 'year',
	},
	{
		amount: 500,
		price: 600,
		period: 'year',
	},
	{
		amount: 1000,
		price: 1100,
		period: 'year',
	},
	{
		amount: 2000,
		price: 2000,
		period: 'year',
	},
	{
		amount: 5000,
		price: 4000,
		period: 'year',
	},
	{
		amount: 10000,
		price: 9800,
		period: 'year',
	},
	{
		amount: 20000,
		price: 18000,
		period: 'year',
	},
];


export const textProcessingPrices = [
	// Monthly prices
	{
		amount: 100,
		price: 10,
		period: 'month',
	},
	{
		amount: 200,
		price: 15,
		period: 'month',
	},
	{
		amount: 500,
		price: 25,
		period: 'month',
	},
	{
		amount: 1000,
		price: 30,
		period: 'month',
	},
	{
		amount: 2000,
		price: 50,
		period: 'month',
	},
	{
		amount: 5000,
		price: 120,
		period: 'month',
	},
	{
		amount: 10000,
		price: 230,
		period: 'month',
	},
	{
		amount: 20000,
		price: 460,
		period: 'month',
	},
	// Yearly prices
	{
		amount: 100,
		price: 100,
		period: 'year',
	},
	{
		amount: 200,
		price: 150,
		period: 'year',
	},
	{
		amount: 500,
		price: 250,
		period: 'year',
	},
	{
		amount: 1000,
		price: 300,
		period: 'year',
	},
	{
		amount: 2000,
		price: 500,
		period: 'year',
	},
	{
		amount: 5000,
		price: 1200,
		period: 'year',
	},
	{
		amount: 10000,
		price: 2300,
		period: 'year',
	},
	{
		amount: 20000,
		price: 4600,
		period: 'year',
	},
];

export enum ProcessingStatus {
	NOT_STARTED = 'NotStarted',
	RUNNING = 'Running',
	FAILED = 'Failed',
	STOPPED = 'Stopped',
	COMPLETED = 'Completed',
	COMPLETED_WITH_ERRORS = 'CompletedWithErrors',
	UPLOADING = 'Uploading',
	UPLOADED = 'Uploaded',
}

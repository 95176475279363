import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Services } from "services/Services";

export const ContactUs: React.FC = (props) => {
	const services: Services = new Services();
	const [isDisabled, setIsDisabled] = useState(true);
	const [message, setMessage] = useState("");
	const [captcha, setCaptcha] = useState("");
	const [feedbackVisible, setFeedbackVisible] = useState(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");

	useEffect(() => {
		console.log(`Name: ${name}`);
		console.log(`Email: ${email}`);
		console.log(`Captcha: ${captcha}`);
		if (name && email && captcha) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	}, [name, email, captcha]);

	function onChange(value) {
		console.log("Captcha value:", value);
		setCaptcha(value);
	}

	function onSubmit(e) {
		e.preventDefault();
		console.log(e);
		setFeedbackVisible(true);
		services.communicationService.sendContactRequest({
			name,
			email,
			message,
		});
	}

	return (
		<section className="mbr-section form1 cid-s6JG3Jhnxd" id="form1-14">
			<div className="container">
				<div className="row justify-content-center">
					<div className="title col-12 col-lg-8">
						<h2 className="mbr-section-title align-center pb-3 mbr-fonts-style display-2">CONTACT FORM</h2>
						<h3 className="mbr-section-subtitle align-center mbr-light pb-3 mbr-fonts-style display-5">
							Keep in touch! <br />
							Something to say? Tell us, our pleasure.
						</h3>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row justify-content-center">
					<div className="media-container-column col-lg-8">
						<form onSubmit={onSubmit} method="POST" className="mbr-form form-with-styler">
							<input
								type="hidden"
								name="email"
								data-form-email="true"
								defaultValue="0VnTELw6/UZygY579Ug0xJb4QGOwKCqr9+H1Y1Au2Q6z7Ifla8goHPumw/lgDUrsJjbZYU1BKvP9AphFbRW+c4UbdkP4Fttbd2f1rgux91cHY6nTVnDz1MYOcsYUZYpQ"
							/>
							<div className="row">
								<div hidden={!feedbackVisible} data-form-alert className="alert alert-success col-12">
									Thanks for filling out the form! We appreciate your thoughts!
								</div>
							</div>
							<div className="dragArea row">
								<div className="col-md-4  form-group">
									<label htmlFor="name-form1-14" className="form-control-label mbr-fonts-style display-7">
										Name
									</label>
									<input
										type="text"
										name="name"
										required={true}
										className="form-control display-7"
										placeholder="Tell Usher"
										id="name-form1-14"
										value={name}
										onChange={(event) => {
											setName(event.target.value);
										}}
									/>
								</div>
								<div className="col-md-4  form-group">
									<label htmlFor="email-form1-14" className="form-control-label mbr-fonts-style display-7">
										Email
									</label>
									<input
										// type="email"
										name="email"
										required={true}
										className="form-control display-7"
										placeholder="tell.us@your.email"
										pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
										id="email-form1-14"
										value={email}
										onChange={(event) => {
											setEmail(event.target.value);
										}}
									/>
								</div>
								<div data-for="message" className="col-md-12 form-group">
									<label htmlFor="message-form1-14" className="form-control-label mbr-fonts-style display-7">
										Message
									</label>
									<textarea
										name="message"
										data-form-field="Message"
										className="form-control display-7"
										placeholder="Feel free to share your thoughts."
										id="message-form1-14"
										value={message}
										onChange={(event) => {
											setMessage(event.target.value);
										}}
									/>
								</div>
								<div className="col-md-12 form-group">
									<ReCAPTCHA sitekey="6Lf_uLsZAAAAAMBSmu8KHvXmu08JyL9kOe3i7lbN" onChange={onChange} />
								</div>
								<div className="col-md-12 input-group-btn align-center">
									<button type="submit" className="btn btn-primary btn-form display-4" disabled={isDisabled}>
										SEND FORM
									</button>
								</div>
							</div>
						</form>
						{/*-Formbuilder Form-*/}
					</div>
				</div>
			</div>
		</section>
	);
};

import { ModelService } from './ModelService';
import { OsrService } from './OsrService';
import { OcrService } from './OcrService';
import { HelperService } from './HelperService';
import { UserService } from './UserService';
import { CookieService } from './CookieService';
import { CommunicationService } from './CommunicationService';
import { TablesService } from './TablesService';
import { LocalStorageService } from 'services/LocalStorageService';
import { SessionStorageService } from 'services/SessionStorageService';
import { LogsService } from './LogsService';
import { InvoiceService } from './InvoiceService';
import { OrganizationService } from './OrganizationService';
import { SubscriptionsService } from './SubscriptionsService';
import { PdfService } from './PdfService';
import { StripeService } from './StripeService';
import { StatisticsService } from './StatisticsService';

export class Services<T = any> {
	private _modelService: ModelService;
	public get modelService(): ModelService {
		return this._modelService;
	}
	public set modelService(v: ModelService) {
		this._modelService = v;
	}

	private _osrService: OsrService;
	public get osrService(): OsrService {
		return this._osrService;
	}
	public set osrService(v: OsrService) {
		this._osrService = v;
	}

	private _ocrService: OcrService;
	public get ocrService(): OcrService {
		return this._ocrService;
	}
	public set ocrService(v: OcrService) {
		this._ocrService = v;
	}

	private _tablesService: TablesService;
	public get tablesService(): TablesService {
		return this._tablesService;
	}
	public set tablesService(v: TablesService) {
		this._tablesService = v;
	}

	private _helperService: HelperService;
	public get helperService(): HelperService {
		return this._helperService;
	}
	public set helperService(v: HelperService) {
		this._helperService = v;
	}

	private _userService: UserService;
	public get userService(): UserService {
		return this._userService;
	}
	public set userService(v: UserService) {
		this._userService = v;
	}

	private _cookieService: CookieService;
	public get cookieService(): CookieService {
		return this._cookieService;
	}
	public set cookieService(v: CookieService) {
		this._cookieService = v;
	}

	private _communicationService: CommunicationService;
	public get communicationService(): CommunicationService {
		return this._communicationService;
	}
	public set communicationService(v: CommunicationService) {
		this._communicationService = v;
	}

	private _localStorage: LocalStorageService<T>;
	public get localStorage(): LocalStorageService<T> {
		return this._localStorage;
	}
	public set localStorage(v: LocalStorageService<T>) {
		this._localStorage = v;
	}

	private _sessionStorage: SessionStorageService<T>;
	public get sessionStorage(): SessionStorageService<T> {
		return this._sessionStorage;
	}
	public set sessionStorage(v: SessionStorageService<T>) {
		this._sessionStorage = v;
	}

	private _logService: LogsService;
	public get logService(): LogsService {
		return this._logService;
	}
	public set logService(v: LogsService) {
		this._logService = v;
	}

	private _invoiceService: InvoiceService;
	public get invoiceService(): InvoiceService {
		return this._invoiceService;
	}
	public set invoiceService(v: InvoiceService) {
		this._invoiceService = v;
	}

	private _organizationService: OrganizationService;
	public get organizationService(): OrganizationService {
		return this._organizationService;
	}
	public set organizationService(v: OrganizationService) {
		this._organizationService = v;
	}

	private _subscriptionsService: SubscriptionsService;
	public get subscriptionsService(): SubscriptionsService {
		return this._subscriptionsService;
	}
	public set subscriptionsService(v: SubscriptionsService) {
		this._subscriptionsService = v;
	}

	private _pdfService: PdfService;
	public get pdfService(): PdfService {
		return this._pdfService;
	}
	public set pdfService(v: PdfService) {
		this._pdfService = v;
	}

	private _stripeService: StripeService;
	public get stripeService(): StripeService {
		return this._stripeService;
	}
	public set stripeService(v: StripeService) {
		this._stripeService = v;
	}

	private _statisticsServices: StatisticsService;
	public get statisticsServices(): StatisticsService {
		return this._statisticsServices;
	}
	public set statisticsServices(v: StatisticsService) {
		this._statisticsServices = v;
	}

	/**
	 * Default constructor
	 */
	constructor() {
		this._osrService = new OsrService();
		this._ocrService = new OcrService();
		this._modelService = new ModelService();
		this._helperService = new HelperService();
		this._userService = new UserService();
		this._cookieService = new CookieService();
		this._communicationService = new CommunicationService();
		this._tablesService = new TablesService();
		this._localStorage = new LocalStorageService<T>();
		this._sessionStorage = new SessionStorageService<any>();
		this._logService = new LogsService();
		this._invoiceService = new InvoiceService();
		this._organizationService = new OrganizationService();
		this._subscriptionsService = new SubscriptionsService();
		this._pdfService = new PdfService();
		this._stripeService = new StripeService();
		this._statisticsServices = new StatisticsService();
	}
}

import { createGlobalState } from 'react-hooks-global-state';
import { IOrganization } from './IOrganizationModel';
import { IUserModel } from './IUserModel';
import { StripeConfig } from './stripe/StripeConfig';
import { ISubscriptionSummaryDto } from './subscription-summary/subscription.summary.dto';

export const { useGlobalState } = createGlobalState({
	snackVisible: false,
	snackMessage: '',
	snackSeverity: '',
	loggedIn: false,
	loggedInUser: {} as IUserModel,
	organization: null as IOrganization,
	subscriptionsSummary: null as ISubscriptionSummaryDto,
	stripeConfig: null as StripeConfig,
	subscriptionSummaryReloadNeeded: false,
});

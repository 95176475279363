import { CookieService } from './CookieService';
import ReactGA from 'react-ga';

export class StatisticsService {
	private cookieService: CookieService;

	private _statisticsCookie: boolean;
	public get statisticsCookie(): boolean {
		if (this._statisticsCookie === undefined) {
			this._statisticsCookie = this.cookieService.getCookie('rcl_statistics_consent') === 'true' ? true : false;
		}
		return this._statisticsCookie;
	}
	public set statisticsCookie(v: boolean) {
		this._statisticsCookie = v;
	}

	/**
	 *
	 */
	constructor() {
		this.cookieService = new CookieService();
	}

	public registerEvent(category: string, action: string) {
		if (this.statisticsCookie === true) {
			ReactGA.event({
				category: category,
				action: action,
			});
		}
	}

	public registerPageView(path: string) {
		if (this.statisticsCookie === true) {
			ReactGA.pageview(path);
		}
	}
}

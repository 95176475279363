import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { CopyRight } from '../footer/CopyRight';
import { Services } from '../../services/Services';
import { useGlobalState } from '../../models/GlobalStates';
import { useHistory } from 'react-router-dom';
import { ErrorModel } from '../../models/IErrorModel';
import { Severity } from '../../models/Severity';
import { SnackBarControl } from 'components/layout/SnackBar';
import { Grid, Link } from '@material-ui/core';
import { IUserModel } from 'models/IUserModel';
import { NavigationLinks } from 'models/NavigationLinks';
import GoogleLogin from 'react-google-login';
import { AppStore, IAppStoreModel } from 'models/AppStore';
import { MessageType } from 'models/IMessagePlaceHolder';
import { MessagePlaceHolder } from 'components/layout/MessagePlaceHolder';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		padding: theme.spacing(2, 0, 2),
	},
}));

export default function SignIn() {
	const classes = useStyles();
	const history = useHistory();
	const [snackVisible, setSnackVisible] = useGlobalState('snackVisible');
	const [snackMessage, setSnackMessage] = useGlobalState('snackMessage');
	const [snackSeverity, setSnackSeverity] = useGlobalState('snackSeverity');
	const services: Services = new Services();
	const [email, setEmail] = useState('');
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
	const [password, setPassword] = useState('');
	const [googleLoginDisabled, setGoogleLoginDisabled] = useState(false);

	useEffect(() => {
		if (email) {
			setNextButtonEnabled(validateEmail());
		}
	}, [email]);

	// ************************************************************************************************************************************************************
	// Error handling
	// ************************************************************************************************************************************************************
	function onError(err) {
		AppStore.update((s: IAppStoreModel) => {
			s.messages = { visible: true, message: err.message, type: MessageType.ERROR };
		});
	}

	function validateForm() {
		return validateEmail() && password.length > 0;
	}

	function validateEmail() {
		return services.helperService.validateEmail(email);
	}

	const handleSubmit = async (event: any) => {
		try {
			event.preventDefault();
			const result = await services.userService.login(email, password);
			if (result) {
				services.sessionStorage.set('access_token', result.access_token);
				// services.cookieService.setCookie('access_token', result.access_token, {
				// 	path: NavigationLinks.PUBLIC,
				// });
				// setLoggedIn(true);
				// const user: IUserModel = await services.userService.getMe(result.access_token);
				// setLoggedInUser(user);
				history.push(NavigationLinks.APP);
			}
		} catch (error) {
			onError(error);
		}
	};

	const handleClose = () => {
		setSnackVisible(false);
	};

	const handleLogin = async (googleData) => {
		try {
			if (!googleData.error) {
				const result = await services.userService.loginWithGoogle(googleData.tokenId);
				if (result) {
					services.sessionStorage.set('access_token', result.access_token);
					// services.cookieService.setCookie('access_token', result.access_token, {
					// 	path: NavigationLinks.PUBLIC,
					// });
					history.push(NavigationLinks.APP);
				}
			} else {
				if (googleData.error === 'idpiframe_initialization_failed') {
					setGoogleLoginDisabled(true);
					onError(new Error('Google auth will not work. Third party cookies must be enabled.'));
				} else {
					onError(new Error(googleData.error + ' ' + googleData.details));
				}
			}
		} catch (error) {
			onError(error);
		}
	};

	const handleEmailKeyDown = (e) => {
		if (e.key === 'Enter' && validateEmail()) {
			e.preventDefault();
			setPasswordVisible(true);
		}
	};

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<a href="/">
					<img src="/assets/images/logo.svg" alt="walheda.com" title="Walheda" style={{ height: '3.8rem' }} />
				</a>
				<Grid container style={{ paddingTop: '50px' }} alignItems="center" direction="column" justify="center">
					<Grid item xs={12}>
						<Typography component="h1" variant="h5">
							SIGN IN - {!passwordVisible && 'Email'}
							{passwordVisible && 'Password'}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{!passwordVisible && <Typography variant="body2">Type the registered email</Typography>}
						{passwordVisible && (
							<Button
								color="primary"
								onClick={() => {
									setPasswordVisible(false);
								}}
							>
								{email}
							</Button>
						)}
					</Grid>
				</Grid>
				<form className={classes.form} onSubmit={handleSubmit}>
					{!passwordVisible && (
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="User email"
							name="user email"
							autoComplete="email"
							autoFocus={true}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							onKeyDown={handleEmailKeyDown}
						/>
					)}
					{passwordVisible && (
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoFocus={true}
							autoComplete="current-password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					)}
					{!passwordVisible && (
						<Button
							type="button"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							onClick={() => {
								setPasswordVisible(true);
							}}
							disabled={!nextButtonEnabled}
						>
							NEXT
						</Button>
					)}
					{passwordVisible && (
						<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} disabled={!validateForm()}>
							SIGN IN
						</Button>
					)}
				</form>
				<Box mt={2}>
					<GoogleLogin
						clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
						buttonText="Log in with Google"
						disabled={googleLoginDisabled}
						onSuccess={handleLogin}
						onFailure={handleLogin}
						cookiePolicy={'single_host_origin'}
						isSignedIn={false}
						theme="dark"
						style={{ width: '100%' }}
					/>
				</Box>
			</div>
			<Box mt={4}>
				<CopyRight />
			</Box>
			<Box display="flex" justifyContent="center" mt={4}>
				<Link href={NavigationLinks.REGISTER} style={{ textDecoration: 'none' }}>
					REGISTER
				</Link>
			</Box>
			<Box display="flex" justifyContent="center" m={1}>
				<Link href={NavigationLinks.PASSWORD_RESET} style={{ textDecoration: 'none' }}>
					I forgot my password
				</Link>
			</Box>
			<MessagePlaceHolder />
		</Container>
	);
}

import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
	centerContainer: {
		height: '100%',
		position: 'relative',
		border: 'none',
	},
	centerContainerSection: {
		margin: '0',
		position: 'absolute',
	},
}));

export default function RegisterSuccess() {
	const classes = useStyles();

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.centerContainer}>
				<section id="wrapper" className={classes.centerContainerSection}>
					<div style={{ padding: '30px' }}>
						<p style={{ textAlign: 'center', fontSize: '36px', fontWeight: 'bold', color: '#2da02d' }}>SUCCESS...</p>
						<p style={{ textAlign: 'center' }}>
							<img src="/assets/images/register/success.gif" style={{ width: '300px' }} alt='Registration successful' />
						</p>
						<p style={{ textAlign: 'center', fontSize: '20px' }}>... but needs review!</p>
						<p style={{ textAlign: 'center', fontSize: '20px' }}>Thank you for your registration! It will be reviewed by our crew and you'll be notified by email once it is activated. Thank you for your patience!</p>

						<p style={{ textAlign: 'center', fontSize: '25px', marginTop: '40px' }}>
							<Button href="/app" variant="text" color={'primary'} style={{ textDecoration: 'none' }}>
								<ArrowBackIcon />
								OPEN DASHBOARD
							</Button>
						</p>
					</div>
				</section>
			</div>
		</Container>
	);
}
